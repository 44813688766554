import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_API_URL || 'https://api.v-ems.com';

export const getModelInstances = async (type = null, ownership = 'all') => {
  try {
    const params = new URLSearchParams();
    if (type) params.append('type', type);
    if (ownership) params.append('ownership', ownership);
    
    const response = await api.get('/model-instances', { params });
    return response.data.model_instances;
  } catch (error) {
    console.error('Error fetching model instances:', error);
    throw error;
  }
};

const api = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true
});

api.interceptors.request.use((config) => {
  if (!config.url.includes('/auth/login')) {
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('authToken');
    }
    return Promise.reject(error);
  }
);

export const authService = {
  login: async (credentials) => {
    try {
      const response = await api.post('/auth/login', {
        username: credentials.identifier,
        password: credentials.password
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true
      });

      if (response.data?.tokens?.access_token) {
        localStorage.setItem('authToken', response.data.tokens.access_token);
        localStorage.setItem('refreshToken', response.data.tokens.refresh_token);
        
        return {
          success: true,
          token: response.data.tokens.access_token,
          refreshToken: response.data.tokens.refresh_token,
          message: response.data.message
        };
      } else {
        return {
          success: false,
          message: response.data.message || 'Invalid server response: No tokens received'
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error.response?.data?.message || 
                error.message || 
                'Failed to connect to server'
      };
    }
  },
};

export const contactService = {
  sendMessage: async (formData) => {
    const response = await api.post('/api/contact', formData);
    return response.data;
  },
};

export default api; 