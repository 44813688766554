// FAQ component, serves as a frequently asked questions section for the marketing page.
// It includes a list of questions and answers, each in an accordion format.

import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
//import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What is a digital twin?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '100%' } }}
            >
            A digital twin is a virtual model that mirrors a real-world object, system, or environment in real time. 
            Through data collected from sensors and other sources, the digital twin continuously updates to reflect changes and behaviors of its physical counterpart. 
            This technology allows users to monitor, analyze, and even simulate scenarios to make predictions and decisions, enhancing understanding and optimizing performance. 
            <br />
            Digital twins are used in industries like manufacturing, healthcare, and city planning, helping organizations improve efficiency, anticipate issues, and innovate through virtual testing without disrupting real-world operations.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
             How is AI used in VEMS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ 
                maxWidth: { sm: '100%', md: '100%' },
                textAlign: 'justify',
              }}
            >
              AI is not just a feature; it is the engine that drives the creation and refinement of highly accurate Digital Twins for each client, ensuring they continuously adapt to real-world conditions. 
              VEMS&apos; AI models constantly analyze vast amounts of data from the energy assets (such as PV systems, batteries, and Heating Systems) to understand their behavior in various scenarios. 
              This data is fed into machine learning algorithms that continuously update the digital twin to reflect the most accurate real-time state of the assets. 
              AI enables VEMS to capture even the smallest deviations in performance and adjust the digital twin accordingly.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What are the benefits of using VEMS?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
              <li><strong>Seamless digital twin integration:</strong> VEMS creates and updates digital twins effortlessly, providing a real-time, adaptive model of your energy assets that integrates with third parties services and scales with your business needs;</li>
              <li><strong>Comprehensive energy insights:</strong> VEMS delivers a detailed digital twin-based energy assessment, allowing you to easily understand and monitor your energy usage and make informed decisions;</li>
              <li><strong>Cost and energy savings:</strong> Through digital twin predictive control, VEMS reduces energy costs by around 24% and achieves energy savings of 20-21%;</li>
              <li><strong>Reduction of carbon footprint & increased grid stability:</strong> VEMS helps lower greenhouse gas emissions while contributing to overall grid stability through optimized asset performance.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
              What makes VEMS stand out in the market? 
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ul style={{ margin: 0, paddingLeft: '1.5rem' }}>
              <li><strong>Digital twin predictive control:</strong> Unlike many established rule-based energy management systems, VEMS creates accurate digital twins to predict energy flows and choose the best optimization strategy beforehand;</li>
              <li><strong>Modular, hardware-agnostic platform:</strong> VEMS integrates seamlessly with any existing energy assets, making it flexible and scalable for businesses of varying sizes without the need for costly hardware upgrades;</li>
              <li><strong>End-to-end energy management:</strong> From assessment and optimization to an integrated marketplace for energy solutions, VEMS offers a full-service platform, delivering digital twin value in full to our clients.</li>
            </ul>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
