import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import AssetLibrary from '../asset-library/AssetLibrary';
import simulationService from '../../../../services/simulationService';

const columns = [
  { 
    field: 'simulationName', 
    headerName: 'Simulation Name', 
    flex: 2,
    renderCell: (params) => (
      <span>{params.value}</span>
    )
  },
  { 
    field: 'createdAt', 
    headerName: 'Created', 
    flex: 1.5,
    valueFormatter: (params) => new Date(params.value).toLocaleString()
  }
];

export default function WidgetSimulationTable() {
  const navigate = useNavigate();
  const [isAssetLibraryOpen, setIsAssetLibraryOpen] = React.useState(false);
  const [simulations, setSimulations] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);

  React.useEffect(() => {
    const fetchSimulations = async () => {
      setLoading(true);
      const result = await simulationService.getSimulations({
        per_page: 50 // Adjust as needed
      });

      if (result.success) {
        const formattedSimulations = result.data.simulations.map(sim => ({
          id: sim.id,
          simulationName: sim.name,
          createdAt: sim.created_at,
          path: sim.id.toString()
        }));
        setSimulations(formattedSimulations);
        setError(null);
      } else {
        setError(result.error);
      }
      setLoading(false);
    };

    fetchSimulations();
  }, []);

  const handleRowClick = (params) => {
    navigate(`/simulator/${params.row.path}`);
  };

  const handleCreateNew = () => {
    navigate('/simulator/new-simulation');
  };

  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Stack 
        direction="row" 
        justifyContent="space-between" 
        alignItems="center" 
        sx={{ mb: 2 }}
      >
        <Box>Your Simulations:</Box>
        <Stack direction="row" spacing={2}>
          <Button
            variant="outlined"
            startIcon={<LibraryBooksIcon />}
            sx={{ 
              borderRadius: 2,
              height: 36.5,
              minWidth: 180
            }}
            onClick={() => setIsAssetLibraryOpen(true)}
          >
            Asset Library
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            sx={{ 
              borderRadius: 2,
              height: 36.5
            }}
            onClick={handleCreateNew}
          >
            Create New Simulation
          </Button>
        </Stack>
      </Stack>
      
      {error && (
        <Typography color="error" sx={{ mb: 2 }}>
          {error}
        </Typography>
      )}
      
      <DataGrid
        rows={simulations}
        columns={columns}
        loading={loading}
        onRowClick={handleRowClick}
        autoHeight
        disableSelectionOnClick
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer',
            bgcolor: 'action.hover'
          }
        }}
      />
      
      <AssetLibrary 
        open={isAssetLibraryOpen}
        onClose={() => setIsAssetLibraryOpen(false)}
      />
    </Box>
  );
} 