import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function WidgetSimulationHeader({ simulationName, clientName, lastUpdate }) {
  return (
    <>
      <Typography variant="h4" gutterBottom>
        {simulationName}
      </Typography>
      <Typography variant="subtitle1" color="text.secondary" gutterBottom>
        Client: {clientName}
      </Typography>
      <Typography variant="subtitle2" color="text.secondary">
        Last Updated: {lastUpdate}
      </Typography>
    </>
  );
}

WidgetSimulationHeader.propTypes = {
  simulationName: PropTypes.string.isRequired,
  clientName: PropTypes.string.isRequired,
  lastUpdate: PropTypes.string.isRequired,
}; 