// InverterTable component, displays a table of inverters with their status, alarms, and total yield

import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Chip from '@mui/material/Chip';

// Mock data - replace with real data later
const inverterData = [
  {
    id: 1,
    name: 'PV 1',
    serialNumber: 'SN2024001',
    status: 'Online',
    alarms: 0,
    totalYield: '234.5 kWh'
  },
  {
    id: 2,
    name: 'PV 2',
    serialNumber: 'SN2024002',
    status: 'Online',
    alarms: 0,
    totalYield: '198.3 kWh'
  },
];

const columns = [
  { 
    field: 'name', 
    headerName: 'Inverter Name', 
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  { 
    field: 'serialNumber', 
    headerName: 'Serial Number', 
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
  { 
    field: 'status', 
    headerName: 'Status', 
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      <Chip
        label={params.value}
        size="small"
        color={params.value === 'Online' ? 'success' : 'error'}
        sx={{ 
          minWidth: 80,
          backgroundColor: params.value === 'Online' ? 'rgba(46, 160, 67, 0.1)' : 'rgba(248, 81, 73, 0.1)',
          color: params.value === 'Online' ? '#2EA043' : '#F85149',
          borderRadius: '10px'
        }}
      />
    ),
  },
  { 
    field: 'alarms', 
    headerName: 'Alarms', 
    flex: 1,
    align: 'center',
    headerAlign: 'center',
    renderCell: (params) => (
      params.value > 0 ? (
        <Chip
          label={params.value}
          size="small"
          color="error"
          sx={{ 
            minWidth: 30,
            backgroundColor: 'rgba(248, 81, 73, 0.1)',
            color: '#F85149'
          }}
        />
      ) : params.value
    ),
  },
  { 
    field: 'totalYield', 
    headerName: 'Total Yield', 
    flex: 1,
    align: 'center',
    headerAlign: 'center',
  },
];

export default function InverterTable() {
  return (
    <DataGrid
      autoHeight
      rows={inverterData}
      columns={columns}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      initialState={{
        pagination: { paginationModel: { pageSize: 5 } },
      }}
      pageSizeOptions={[5, 10, 20]}
      disableColumnResize
      density="comfortable"
      disableRowSelectionOnClick
      sx={{
        '& .MuiDataGrid-cell:focus': {
          outline: 'none',
        },
      }}
    />
  );
} 