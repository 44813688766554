import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  List, 
  ListItemButton, 
  ListItemIcon, 
  ListItemText
} from '@mui/material';
import { ASSET_CATEGORIES } from '../data/AssetCategories';

export default function AssetCategoryList({ selectedCategory = null, onCategorySelect }) {
  return (
    <List sx={{ py: 0 }}>
      {ASSET_CATEGORIES.map((category) => (
        <ListItemButton
          key={category.id}
          selected={selectedCategory?.id === category.id}
          onClick={() => onCategorySelect(category)}
          sx={{
            py: 2,
            '&.Mui-selected': {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <ListItemIcon sx={{ color: 'primary.main' }}>
            {category.icon}
          </ListItemIcon>
          <ListItemText 
            primary={category.name}
            secondary={category.description}
            primaryTypographyProps={{
              fontWeight: selectedCategory?.id === category.id ? 600 : 400
            }}
          />
        </ListItemButton>
      ))}
    </List>
  );
}

AssetCategoryList.propTypes = {
  selectedCategory: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.string
  }),
  onCategorySelect: PropTypes.func.isRequired
}; 