// Widget Account Manager containing the picture and the name of the account manager, 
// Chat and Schedule a call buttons, and the email.

import * as React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import WidgetChat from './WidgetChat';
import Popover from '@mui/material/Popover';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

// Constants
const ACCOUNT_MANAGER = {
  name: 'Jupiter Notebook',
  title: 'Senior Account Manager',
  email: 'jupiter.frostino@v-ems.com',
  avatarUrl: '/images/UserInterfaceImages/jupiter_account_manager.jpeg',
};

// Profile Section Component
const ProfileSection = ({ name, title, avatarUrl }) => (
  <Stack
    direction="row"
    spacing={2}
    alignItems="center"
    sx={{ mb: 2 }}
  >
    <Avatar
      alt={name}
      src={avatarUrl}
      sx={{ width: 64, height: 64 }}
      imgProps={{
        onError: (e) => {
          e.target.src = '/images/default-avatar.png';
        },
      }}
    />
    <Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
        {name}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {title}
      </Typography>
    </Box>
  </Stack>
);

ProfileSection.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
};

export default function WidgetAccountManager({ loading = false }) {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleChatToggle = () => {
    setIsChatOpen(!isChatOpen);
  };

  const handleScheduleCall = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseCalendar = () => {
    setAnchorEl(null);
  };
 
  if (loading) {
    return (
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Stack spacing={2}>
            {/* Add your loading skeleton here */}
          </Stack>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent sx={{ p: 3 }}>
        <Stack 
          direction="row" 
          spacing={1} 
          alignItems="center" 
          sx={{ mb: 3 }}
        >
          <PersonOutlineRoundedIcon 
            sx={{ 
              color: 'text.secondary',
              fontSize: 20 
            }} 
          />
          <Typography
            component="h2"
            variant="subtitle2"
            sx={{ fontWeight: '600' }}
          >
            Account Manager
          </Typography>
        </Stack>
        
        <Stack spacing={3}>
          <ProfileSection
            name={ACCOUNT_MANAGER.name}
            title={ACCOUNT_MANAGER.title}
            avatarUrl={ACCOUNT_MANAGER.avatarUrl}
          />

          {/* Contact Buttons */}
          <Stack spacing={2}>
            <Button
              variant="contained"
              size="small"
              startIcon={<MessageRoundedIcon />}
              onClick={handleChatToggle}
              aria-label="Open chat with account manager"
              sx={{
                justifyContent: 'flex-start',
                bgcolor: 'primary.main',
                py: 1,
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              Chat Now
            </Button>
            
            <Button
              variant="contained"
              size="small"
              startIcon={<CalendarMonthRoundedIcon />}
              onClick={handleScheduleCall}
              aria-label="Schedule a call with account manager"
              sx={{
                justifyContent: 'flex-start',
                bgcolor: 'primary.main',
                py: 1,
                '&:hover': {
                  bgcolor: 'primary.dark',
                },
              }}
            >
              Schedule a Call
            </Button>
          </Stack>

          {/* Email Contact */}
          <Stack
            direction="row"
            spacing={1.5}
            alignItems="center"
            component="a"
            href={`mailto:${ACCOUNT_MANAGER.email}`}
            sx={{ 
              pt: 2,
              mt: 1,
              borderTop: 1,
              borderColor: 'divider',
              textDecoration: 'none',
              cursor: 'pointer',
              '&:hover': {
                color: 'primary.main',
              },
            }}
          >
            <EmailRoundedIcon 
              fontSize="small"
              sx={{ color: 'text.secondary' }}
            />
            <Typography
              variant="body2"
              sx={{ 
                color: 'text.secondary',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {ACCOUNT_MANAGER.email}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{
          '& .MuiPopover-paper': {
            width: 320,
            p: 2,
          },
        }}
      >
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          Select a Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar 
            onChange={(newValue) => {
              console.log('Selected date:', newValue);
              handleCloseCalendar();
            }}
            sx={{ 
              width: '100%',
              '& .MuiPickersCalendarHeader-root': {
                pl: 2,
                pr: 2,
              },
            }}
          />
        </LocalizationProvider>
      </Popover>

      <WidgetChat
        isOpen={isChatOpen}
        onClose={handleChatToggle}
        managerName={ACCOUNT_MANAGER.name}
      />
    </Card>
  );
}

WidgetAccountManager.propTypes = {
  loading: PropTypes.bool,
}; 