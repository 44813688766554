// AppAppBar component, serves as the app bar of the marketing page
// It includes a sitemark icon, a set of navigation buttons, and a color mode icon dropdown.

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import Sitemark from './SitemarkIcon';
import ColorModeIconDropdown from '../../shared-theme/ColorModeIconDropdown';
import SignIn from '../sign-in/SignIn';
import Dialog from '@mui/material/Dialog';
/* import { useNavigate } from 'react-router-dom'; */

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexShrink: 0,
  borderRadius: `calc(${theme.shape.borderRadius}px + 8px)`,
  backdropFilter: 'blur(24px)',
  border: '1px solid',
  borderColor: (theme.vars || theme).palette.divider,
  backgroundColor: theme.vars
    ? `rgba(${theme.vars.palette.background.defaultChannel} / 0.4)`
    : alpha(theme.palette.background.default, 0.4),
  boxShadow: (theme.vars || theme).shadows[1],
  padding: '8px 12px',
}));

export default function AppAppBar() {
  const [open, setOpen] = React.useState(false);
  const [signInOpen, setSignInOpen] = React.useState(false);
  /* const navigate = useNavigate(); */

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const handleSignInOpen = () => {
    setSignInOpen(true);
  };

  const handleSignInClose = () => {
    setSignInOpen(false);
  };

  const scrollToHero = () => {
    // Scroll to top smoothly
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <AppBar
        position="fixed"
        enableColorOnDark
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          mt: 'calc(var(--template-frame-height, 0px) + 28px)',
        }}
      >
        <Container maxWidth="lg">
          <StyledToolbar variant="dense" disableGutters>
            <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', px: 0 }}>
              <Box 
                onClick={scrollToHero}
                sx={{ 
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Sitemark />
              </Box>
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  sx={{ minWidth: 0, fontSize: '1rem' }}
                  href="#vision"
                >
                  Vision
                </Button>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  sx={{ fontSize: '1rem' }}
                  href="#features"
                >
                  Features
                </Button>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  sx={{ minWidth: 0, fontSize: '1rem' }}
                  href="#faq"
                >
                  FAQ
                </Button>
                <Button
                  variant="text"
                  color="info"
                  size="small"
                  sx={{ minWidth: 0, fontSize: '1rem' }}
                  href="#contactus"
                >
                  Contact Us
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 1,
                alignItems: 'center',
              }}
            >
              <Button 
                color="primary" 
                variant="text" 
                size="small"
                onClick={handleSignInOpen}
              >
                Sign in
              </Button>
              <Button color="primary" variant="contained" size="small">
                Sign up
              </Button>
              <ColorModeIconDropdown />
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1 }}>
              <ColorModeIconDropdown size="medium" />
              <IconButton aria-label="Menu button" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="top"
                open={open}
                onClose={toggleDrawer(false)}
                PaperProps={{
                  sx: {
                    top: 'var(--template-frame-height, 0px)',
                  },
                }}
              >
                <Box sx={{ p: 2, backgroundColor: 'background.default' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <IconButton onClick={toggleDrawer(false)}>
                      <CloseRoundedIcon />
                    </IconButton>
                  </Box>

                  <MenuItem component="a" href="#vision">Vision</MenuItem>
                  <MenuItem component="a" href="#features">Features</MenuItem>
                  <MenuItem component="a" href="#faq">FAQ</MenuItem>
                  <MenuItem component="a" href="#contactus">Contact Us</MenuItem>
                  <Divider sx={{ my: 3 }} />
                  <MenuItem>
                    <Button color="primary" variant="contained" fullWidth>
                      Sign up
                    </Button>
                  </MenuItem>
                  <MenuItem>
                    <Button 
                      color="primary" 
                      variant="outlined" 
                      fullWidth
                      onClick={() => {
                        handleSignInOpen();
                        toggleDrawer(false)(); // Close the drawer
                      }}
                    >
                      Sign in
                    </Button>
                  </MenuItem>
                </Box>
              </Drawer>
            </Box>
          </StyledToolbar>
        </Container>
      </AppBar>

      {/* Update Dialog content */}
      <Dialog
        open={signInOpen}
        onClose={handleSignInClose}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            maxWidth: '400px',
            backgroundColor: 'background.default',
            backgroundImage: 'none',
            boxShadow: (theme) => theme.shadows[24],
            margin: 0,
            '& .MuiDialogContent-root': {
              padding: 0,
            }
          }
        }}
      >
        <SignIn onClose={handleSignInClose} />
      </Dialog>
    </>
  );
}
