import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Stack,
  IconButton,
  Typography,
  Box,
  Chip,
  InputAdornment
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';

const CATEGORY_PARAMS = {
  pv: [
    { name: 'power', label: 'Power', unit: 'W' },
    { name: 'efficiency', label: 'Efficiency', unit: '%' },
    { name: 'warranty', label: 'Warranty', unit: 'years' }
  ],
  storage: [
    { name: 'capacity', label: 'Capacity', unit: 'kWh' },
    { name: 'power', label: 'Power', unit: 'kW' },
    { name: 'warranty', label: 'Warranty', unit: 'years' }
  ],
  loads: [
    { name: 'peakPower', label: 'Peak Power', unit: 'kW' },
    { name: 'dailyConsumption', label: 'Daily Consumption', unit: 'kWh' }
  ],
  tariffs: [
    { name: 'peakRate', label: 'Peak Rate', unit: '€/kWh' },
    { name: 'offPeakRate', label: 'Off-Peak Rate', unit: '€/kWh' }
  ],
  controllers: [
    { name: 'algorithm', label: 'Algorithm', unit: '' },
    { name: 'updateRate', label: 'Update Rate', unit: 'min' }
  ]
};

export default function CreateAssetDialog({ open, onClose, category, onSubmit, editingAsset }) {
  const [assetData, setAssetData] = React.useState({
    name: '',
    manufacturer: '',
    specs: {},
    tags: []
  });
  const [newTag, setNewTag] = React.useState('');

  React.useEffect(() => {
    if (editingAsset) {
      setAssetData({
        name: editingAsset.name,
        manufacturer: editingAsset.manufacturer,
        specs: Object.entries(editingAsset.specs).reduce((acc, [key, value]) => {
          const param = CATEGORY_PARAMS[category?.id]?.find(p => p.name === key);
          acc[key] = value.replace(param?.unit || '', '');
          return acc;
        }, {}),
        tags: editingAsset.tags
      });
    } else {
      setAssetData({
        name: '',
        manufacturer: '',
        specs: {},
        tags: []
      });
    }
  }, [editingAsset, category]);

  const handleParamChange = (paramName, value) => {
    setAssetData(prev => ({
      ...prev,
      specs: {
        ...prev.specs,
        [paramName]: value + (CATEGORY_PARAMS[category?.id]?.find(p => p.name === paramName)?.unit || '')
      }
    }));
  };

  const handleAddTag = () => {
    if (newTag && !assetData.tags.includes(newTag)) {
      setAssetData(prev => ({
        ...prev,
        tags: [...prev.tags, newTag]
      }));
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setAssetData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove)
    }));
  };

  const handleSubmit = () => {
    const assetId = editingAsset ? editingAsset.id : `custom_${Date.now()}`;
    
    const newAsset = { 
      ...assetData,
      id: assetId,
      categoryId: category.id,
      specs: Object.entries(assetData.specs).reduce((acc, [key, value]) => {
        const param = CATEGORY_PARAMS[category?.id]?.find(p => p.name === key);
        acc[key] = value + (param?.unit || '');
        return acc;
      }, {})
    };

    if (onSubmit) {
      onSubmit(newAsset);
    }
    
    setAssetData({
      name: '',
      manufacturer: '',
      specs: {},
      tags: []
    });
    
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
        <Typography component="div" variant="h6">
          {editingAsset ? 'Edit' : 'Create New'} {category?.name}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ color: 'grey.500' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <Stack spacing={3}>
          <TextField
            label="Name"
            fullWidth
            value={assetData.name}
            onChange={(e) => setAssetData(prev => ({ ...prev, name: e.target.value }))}
          />

          <TextField
            label="Manufacturer"
            fullWidth
            value={assetData.manufacturer}
            onChange={(e) => setAssetData(prev => ({ ...prev, manufacturer: e.target.value }))}
          />

          {/* Specifications Section */}
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Specifications
            </Typography>
            <Stack spacing={2}>
              {CATEGORY_PARAMS[category?.id]?.map((param) => (
                <TextField
                  key={param.name}
                  label={param.label}
                  size="small"
                  value={assetData.specs[param.name]?.replace(param.unit, '') || ''}
                  onChange={(e) => handleParamChange(param.name, e.target.value)}
                  InputProps={{
                    endAdornment: param.unit && (
                      <InputAdornment position="end">
                        {param.unit}
                      </InputAdornment>
                    )
                  }}
                />
              ))}
            </Stack>
          </Box>

          {/* Tags Section */}
          <Box>
            <Typography variant="subtitle2" gutterBottom>
              Tags
            </Typography>
            <Stack spacing={1}>
              <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                {assetData.tags.map((tag) => (
                  <Chip
                    key={tag}
                    label={tag}
                    onDelete={() => handleRemoveTag(tag)}
                    size="small"
                  />
                ))}
              </Box>
              <Stack direction="row" spacing={1}>
                <TextField
                  label="New Tag"
                  size="small"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  fullWidth
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleAddTag();
                    }
                  }}
                />
                <Button 
                  onClick={handleAddTag}
                  variant="outlined"
                  startIcon={<AddIcon />}
                >
                  Add
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={onClose}
          variant="outlined"
          sx={{
            color: 'text.primary',
            borderColor: 'divider',
            '&:hover': {
              borderColor: 'primary.main',
              backgroundColor: 'action.hover'
            }
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit}
          variant="contained"
          sx={{
            backgroundColor: 'text.primary',
            color: 'background.paper',
            '&:hover': {
              backgroundColor: 'text.secondary',
            }
          }}
        >
          Create Asset
        </Button>
      </DialogActions>
    </Dialog>
  );
}

CreateAssetDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  category: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string
  }),
  editingAsset: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    manufacturer: PropTypes.string.isRequired,
    specs: PropTypes.object.isRequired,
    tags: PropTypes.arrayOf(PropTypes.string).isRequired
  })
}; 