// SitemarkIcon component, serves as the sitemark icon of the marketing page
// It includes the sitemark icon in both dark and light mode.

import * as React from 'react';
import { useTheme } from '@mui/material/styles';

export default function SitemarkIcon() {
  const theme = useTheme();
  
  return (
    <img 
      src={theme.palette.mode === 'dark' ? "/images/GeneralImages/VEMS_logo_name_white.svg" : "/images/GeneralImages/VEMS_logo_name_dark.svg"}
      alt="VEMS Name" 
      style={{ height: 31, width: 150, marginRight: 16 }}
    />
  );
}
