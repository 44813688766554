// DashboardGrid component, displays the main dashboard grid with insights and inverter table

import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useColorScheme } from '@mui/material/styles';
import DashboardInsights from './DashboardInsights';
import InverterTable from './InverterTable';
import CustomDateTimePicker from './WidgetDatePicker';
import dayjs from 'dayjs';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function DashboardGrid() {
  const { mode } = useColorScheme();
  const SERVICE_ACCOUNT_TOKEN = 'sa-1-frontend-b4ab0aca-3c21-4df0-8a7e-cf4defbec461';
  
  const [dateRange, setDateRange] = React.useState({
    startDate: dayjs().subtract(24, 'hour'),
    endDate: dayjs()
  });

  const formatGrafanaTime = (date) => {
    if (!date) return null;
    // Convert to Unix timestamp in milliseconds for Grafana
    return date.valueOf();
  };

  const panelUrls = React.useMemo(() => {
    const baseParams = new URLSearchParams({
      orgId: "1",
      from: formatGrafanaTime(dateRange.startDate) || "now-24h",
      to: formatGrafanaTime(dateRange.endDate) || "now",
      theme: mode || 'dark',
      authToken: SERVICE_ACCOUNT_TOKEN
    });
    
    return {
      plantPowerFlow: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=14`,
      energyPrices: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=2`,
      solarPowerProduction: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=10`,
      lastSolarPowerProduction: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=9`,
      solarPowerLimitation: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=11`,
      lastSolarPowerLimitation: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=12`,
      serverConnection: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=3`,
      serverHandshake: `https://dashboard.v-ems.com/d-solo/ae3m6rwuxs1kwe/181?${baseParams}&panelId=6`,
    };
  }, [mode, dateRange]);
  
  const handleDateRangeChange = React.useCallback(({ startDate, endDate }) => {
    if (!startDate || !endDate) {
      console.warn('Invalid date range selected');
      return;
    }

    if (endDate.isBefore(startDate)) {
      console.warn('End date cannot be before start date');
      return;
    }

    setDateRange({ startDate, endDate });
  }, []);

  const [showDetails, setShowDetails] = React.useState(false);

  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      width: '100vw',
      maxWidth: '100%',
      margin: '0 auto',
      borderRadius: 0,
      transform: 'none',
    }}>
      <CardContent sx={{ 
        flex: '0 0 auto', 
        pb: 0.5,
        px: 3,
      }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="h2">
            Energy Monitoring Dashboard
          </Typography>
          <CustomDateTimePicker 
            onDateRangeChange={handleDateRangeChange}
            initialStartDate={dateRange.startDate}
            initialEndDate={dateRange.endDate}
          />
        </Stack>
      </CardContent>

      <Box sx={{ 
        flex: 1,
        p: 3,
        display: 'flex', 
        flexDirection: 'column', 
        gap: 2,
        width: '100%',
        minWidth: '100%',
        boxSizing: 'border-box',
        mx: 'auto',
        maxWidth: '100%'
      }}>
        <Box 
          sx={{ 
            display: 'grid',
            gridTemplateAreas: {
              xs: `"power" "prices"`,
              md: `"power prices"`,
              lg: `"power prices"`
            },
            gridTemplateColumns: {
              xs: '1fr',
              md: '1fr 1fr',
              lg: '1fr 1fr'
            },
            gap: 2,
            width: '100%',
            minWidth: '100%',
            height: 'auto',
          }}
        >
          {/* Plant Power Flow */}
          <Box sx={{ gridArea: 'power', height: '300px' }}>
            <iframe
              src={panelUrls.plantPowerFlow}
              width="100%"
              height="100%"
              frameBorder="0"
              title="Plant Power Flow"
              style={{
                border: 'none',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Box>

          {/* Energy Prices */}
          <Box sx={{ gridArea: 'prices', height: '300px' }}>
            <iframe
              src={panelUrls.energyPrices}
              width="100%"
              height="100%"
              frameBorder="0"
              title="Energy Prices"
              style={{
                border: 'none',
                borderRadius: '8px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              }}
            />
          </Box>
        </Box>

        {/* More Details Button */}
        <Button
          onClick={() => setShowDetails(!showDetails)}
          endIcon={showDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          sx={{ alignSelf: 'flex-start', mb: 1 }}
        >
          More Details
        </Button>

        {/* Collapsible Content */}
        <Collapse in={showDetails}>
          <Box 
            sx={{ 
              display: 'grid',
              gridTemplateAreas: {
                xs: `
                  "production"
                  "lastProduction"
                  "limitation"
                  "lastLimitation"
                  "connection"
                  "handshake"
                `,
                md: `
                  "production lastProduction"
                  "limitation lastLimitation"
                  "connection handshake"
                `,
                lg: `
                  "production lastProduction"
                  "limitation lastLimitation"
                  "connection handshake"
                `
              },
              gridTemplateColumns: {
                xs: '1fr',
                md: '1fr 1fr',
                lg: '1fr 1fr'
              },
              gap: 2,
              width: '100%',
              minWidth: '100%',
              height: 'auto',
            }}
          >
            {/* Solar Panel Power Production */}
            <Box sx={{ gridArea: 'production', height: '250px' }}>
              <iframe
                src={panelUrls.solarPowerProduction}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Solar Power Production"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Last Solar Panel Power Production */}
            <Box sx={{ gridArea: 'lastProduction', height: '250px' }}>
              <iframe
                src={panelUrls.lastSolarPowerProduction}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Last Solar Power Production"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Solar Panel Power Limitation */}
            <Box sx={{ gridArea: 'limitation', height: '250px' }}>
              <iframe
                src={panelUrls.solarPowerLimitation}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Solar Power Limitation"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Last Solar Power Limitation (previously Server Handshake) */}
            <Box sx={{ gridArea: 'lastLimitation', height: '250px' }}>
              <iframe
                src={panelUrls.lastSolarPowerLimitation}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Last Solar Power Limitation"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Server Connection Status */}
            <Box sx={{ gridArea: 'connection', height: '200px' }}>
              <iframe
                src={panelUrls.serverConnection}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Server Connection Status"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>

            {/* Latest Server Handshake */}
            <Box sx={{ gridArea: 'handshake', height: '200px' }}>
              <iframe
                src={panelUrls.serverHandshake}
                width="100%"
                height="100%"
                frameBorder="0"
                title="Latest Server Handshake"
                style={{
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                }}
              />
            </Box>
          </Box>
        </Collapse>

        <DashboardInsights />

        <Card variant="outlined" sx={{ 
          width: '100%', 
          minWidth: '100%',
          '& .MuiCardContent-root': {
            p: 2
          }
        }}>
          <CardContent>
            <Typography variant="h6" component="h2" gutterBottom>
              Inverter Status Overview
            </Typography>
            <InverterTable />
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
}