import SolarPowerIcon from '@mui/icons-material/SolarPower';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import * as React from 'react';

export const ASSET_CATEGORIES = [
  {
    id: 'pv',
    name: 'PV Systems',
    icon: <SolarPowerIcon />,
    description: 'Solar photovoltaic systems and components'
  },
  {
    id: 'storage',
    name: 'Energy Storage',
    icon: <BatteryChargingFullIcon />,
    description: 'Battery storage systems and components'
  },
  {
    id: 'loads',
    name: 'Load Profiles',
    icon: <HomeIcon />,
    description: 'Residential and commercial load profiles'
  },
  {
    id: 'tariffs',
    name: 'Tariffs',
    icon: <PaidIcon />,
    description: 'Energy pricing and tariff structures'
  },
  {
    id: 'controllers',
    name: 'Controllers',
    icon: <SettingsIcon />,
    description: 'Energy management and control systems'
  }
]; 