// Widget for the energy profile in the user home page (is integrated in the main grid) 

import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function WidgetEnergyProfile() {
  return (
    <Card variant="outlined" sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Your Energy Profile
        </Typography>
        <Stack spacing={3}>
          <Box>
            <Typography variant="body2" gutterBottom>
              Profile Completeness
            </Typography>
            <LinearProgress variant="determinate" value={60} />
          </Box>
          <Box>
            <Typography variant="body2" gutterBottom>
              Solar Potential
            </Typography>
            <Typography variant="caption" color="text.secondary">
              High - Your roof can support up to 360kW system
            </Typography>
          </Box>
          <Box>
            <Typography variant="body2" gutterBottom>
              Energy Storage
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Not installed - Consider adding a home battery
            </Typography>
          </Box>
          <Button variant="contained" fullWidth>
            Fill in your data
          </Button>
          <Button variant="outlined" fullWidth>
            Your free assessment data
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
} 