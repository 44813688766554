import { getModelInstances } from '../../../../../services/api';

class AssetLibrary {
  constructor() {
    this.assets = {
      pv: [],
      storage: [],
      loads: [],
      tariffs: [],
      controllers: []
    };
    this.initialized = false;
    this.initializationPromise = null;
  }

  transformModelInstance(instance) {
    const baseAsset = {
      id: instance.id.toString(),
      name: instance.name,
      manufacturer: 'System', // Could be added to API in future
      tags: [], // Could be added to API in future
    };
    switch (instance.type) {
      case 'pv':
        return {
          ...baseAsset,
          specs: {
            power: `${instance.params.Module_area * instance.params.Module_Efficiency}kW`,
            efficiency: `${instance.params.Module_Efficiency * 100}%`,
            warranty: '25 years' // Could be added to API in future
          }
        };

      case 'battery':
        return {
          ...baseAsset,
          specs: {
            capacity: `${instance.params.max_energy / 1000} kWh`,
            power: `${instance.params.max_p / 1000} kW`,
            warranty: '10 years' // Could be added to API in future
          }
        };

      case 'load':
        return {
          ...baseAsset,
          specs: {
            peakPower: `${instance.factor * 5} kW`, // Assuming base load is 5kW
            dailyConsumption: `${instance.factor * 30} kWh` // Assuming base consumption is 30kWh
          }
        };

      case 'tariff':
        return {
          ...baseAsset,
          specs: {
            peakRate: `${instance.peak_rate} €/kWh`,
            offPeakRate: `${instance.off_peak_rate} €/kWh`
          }
        };

      case 'controller':
        return {
          ...baseAsset,
          specs: {
            algorithm: 'Peak Shaving',
            updateRate: '15 min'
          }
        };

      default:
        return null;
    }
  }

  async initialize() {
    if (this.initialized) return;
    
    // If initialization is already in progress, return the existing promise
    if (this.initializationPromise) {
      return this.initializationPromise;
    }

    this.initializationPromise = (async () => {
      try {
        const instances = await getModelInstances();
        
        // Reset arrays before populating
        Object.keys(this.assets).forEach(key => {
          this.assets[key] = [];
        });
        
        instances.forEach(instance => {
          const transformedAsset = this.transformModelInstance(instance);
          if (transformedAsset) {
            const categoryMap = {
              pv: 'pv',
              battery: 'storage',
              load: 'loads',
              tariff: 'tariffs',
              controller: 'controllers'
            };
            
            const category = categoryMap[instance.type];
            if (category) {
              this.assets[category].push(transformedAsset);
            }
          }
        });

        this.initialized = true;
      } catch (error) {
        console.error('Failed to initialize asset library:', error);
        throw error;
      } finally {
        this.initializationPromise = null;
      }
    })();

    return this.initializationPromise;
  }

  async getAssets() {
    await this.initialize();
    return this.assets;
  }
}

const assetLibrary = new AssetLibrary();
export default assetLibrary; 