// Hero component, serves as the hero section of the marketing page
// It contains a title and a description

import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';

export default function Hero() {
  const theme = useTheme();
  const vemsLogoName = theme.palette.mode === 'light' 
    ? 'images/GeneralImages/VEMS_name_dark.png' 
    : 'images/GeneralImages/VEMS_name_white.png';
  const vemsLogo = theme.palette.mode === 'light' 
    ? 'images/GeneralImages/vems_logo_dark.png' 
    : 'images/GeneralImages/vems_logo_dark.png';

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundImage:
          'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
        ...theme.applyStyles('dark', {
          backgroundImage:
            'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
        }),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{ alignItems: 'center', width: { xs: '100%', sm: '70%' } }}
        >
          <img 
            src={vemsLogo} 
            alt="VEMS Logo" 
            style={{ 
              width: '300px',  // Increased from 200px to 300px
              height: 'auto', 
              marginTop: '50px',
              marginBottom: '50px',  // Added spacing below the logo
              filter: 'none',
              opacity: '1'
            }}
          />
          <img 
            src={vemsLogoName} 
            alt="VEMS Name" 
            style={{ 
              width: '100%', 
              height: 'auto', 
              marginBottom: '150px' 
            }}
          />
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              fontSize: 'clamp(3rem, 10vw, 3.5rem)',
              textAlign: 'center',
            }}
          >
            Building the
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="span"
                variant="h1"
                sx={(theme) => ({
                  fontSize: 'inherit',
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                Internet of Energy
              </Typography>
            </Box>
          </Typography>
          <Typography
            sx={{
              textAlign: 'center',
              color: 'text.secondary',
              width: { sm: '100%', md: '90%' },
              fontSize: '1.1rem',
            }}
          >
            There is no green future without a digital revolution in energy. 
            <br />
            At VEMS, we&apos;re pioneering the Internet of Energy, where intelligent, 
            interconnected energy assets power a truly sustainable world. Join VEMS, as we lead the way to make the digital transformation of energy a reality.
          </Typography>
        </Stack>
      </Container>
    </Box>
  );
}
