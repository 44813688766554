 // Widget for chat functionality used in the Widget Account Manager

import * as React from 'react';
import { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MessageRoundedIcon from '@mui/icons-material/MessageRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    padding: '8px 12px',
    borderRadius: '8px',
    color: theme.palette.mode === 'dark' ? '#D1D5DB' : '#111827',
    backgroundColor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#374151' : '#D1D5DB'}`,
    boxShadow: `0px 2px 2px ${theme.palette.mode === 'dark' ? '#111827' : '#F3F4F6'}`,

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },

    '&.Mui-focused': {
      outline: 0,
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 3px ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light}`,
    },
  },
}));

export default function ChatWidget({ isOpen, onClose }) {
  const [messages, setMessages] = useState([
    { sender: 'Dominique', content: 'Hello! How can I help you today?' }
  ]);
  const [newMessage, setNewMessage] = useState('');

  const handleSend = () => {
    if (newMessage.trim()) {
      setMessages([...messages, { sender: 'You', content: newMessage.trim() }]);
      setNewMessage('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  if (!isOpen) {
    return (
      <Box sx={{ position: 'fixed', bottom: 32, right: 32, zIndex: 1000 }}>
        <Button
          onClick={onClose}
          sx={{
            width: 56,
            height: 56,
            borderRadius: '999px',
            bgcolor: 'primary.main',
            color: 'primary.contrastText',
            boxShadow: (theme) => theme.shadows[4],
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }}
        >
          <MessageRoundedIcon />
        </Button>
      </Box>
    );
  }

  return (
    <Card
      variant="outlined"
      sx={{
        position: 'fixed',
        bottom: 32,
        right: 32,
        width: 320,
        height: 480,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1000,
        boxShadow: (theme) => theme.shadows[8],
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            alt="Dominique"
            src="/images/UserInterfaceImages/dominique_assistant.jpeg"
            sx={{ width: 32, height: 32 }}
          />
        }
        title="Chat with Dominique"
        titleTypography={{ variant: 'subtitle2', fontWeight: 600 }}
        action={
          <IconButton onClick={onClose} size="small">
            <CloseRoundedIcon />
          </IconButton>
        }
        sx={{ py: 1.5, px: 2, borderBottom: 1, borderColor: 'divider' }}
      />

      <CardContent
        sx={{
          flex: 1,
          overflowY: 'auto',
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          bgcolor: 'background.default'
        }}
      >
        {messages.map((message, index) => (
          <Box
            key={index}
            sx={{
              alignSelf: message.sender === 'You' ? 'flex-end' : 'flex-start',
              bgcolor: message.sender === 'You' ? 'primary.main' : (theme) => 
                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
              color: message.sender === 'You' ? 'primary.contrastText' : 'text.primary',
              borderRadius: 2,
              px: 2,
              py: 1,
              maxWidth: '80%'
            }}
          >
            <Typography variant="body2">{message.content}</Typography>
          </Box>
        ))}
      </CardContent>

      <Box sx={{ p: 2, borderTop: 1, borderColor: 'divider', bgcolor: 'background.paper' }}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <StyledTextField
            fullWidth
            size="small"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            variant="outlined"
          />
          <IconButton 
            onClick={handleSend}
            color="primary"
            disabled={!newMessage.trim()}
            size="small"
            sx={{
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              '&:hover': {
                bgcolor: 'primary.dark',
              },
              '&.Mui-disabled': {
                bgcolor: 'action.disabledBackground',
                color: 'action.disabled',
              }
            }}
          >
            <SendRoundedIcon fontSize="small" />
          </IconButton>
        </Box>
      </Box>
    </Card>
  );
}

ChatWidget.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};