import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import MarketingPage from './components/marketing-page/MarketingPage';
import UserHome from './components/user-interface/user-home/UserHome';
import UserDashboard from './components/user-interface/user-dashboard/UserDashboard';
import { simulatorRoutes } from './components/user-interface/user-simulator/SimulatorRoutes';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<MarketingPage />} />
      <Route path="/home" element={<UserHome />} />
      <Route path="/dashboard" element={<UserDashboard />} />
      {/* Use simulator routes */}
      {simulatorRoutes.map((route) => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
} 