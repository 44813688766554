// Widget for the marketplace (possible offers for the user)

import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid2';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';

export default function WidgetMarketplace({ offers = [] }) {
  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        Our Marketplace
      </Typography>
      <Grid container spacing={2}>
        {offers.map((offer, index) => (
          <Grid key={index} size={{ xs: 12, sm: 6, md: 4 }}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {offer.title}
                </Typography>
                <Typography variant="body2" paragraph>
                  {offer.description}
                </Typography>
                <Box sx={{ mb: 1.5 }}>
                  <Rating 
                    name={`rating-${index}`}
                    value={[5, 3, 3.5][index]}
                    readOnly
                    precision={0.5}
                    max={5}
                  />
                </Box>
                <Button variant="outlined" fullWidth>
                  View Details
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

WidgetMarketplace.propTypes = {
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      rating: PropTypes.number
    })
  )
}; 