// LogoCollection component, serves as the logos section of the marketing page
// It includes a set of logos of the companies that have trusted VEMS

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';


const whiteLogos = [
  '/images/MarketingPageImages/yesdelft_logo_white.svg',
  '/images/MarketingPageImages/sungrow_logo.svg',
  '/images/MarketingPageImages/Janszon_logo_white.png',
  '/images/MarketingPageImages/edmij_logo_dark.png',
];

const darkLogos = [
  '/images/MarketingPageImages/yesdelft_logo_dark.svg',
  '/images/MarketingPageImages/sungrow_logo.svg',
  '/images/MarketingPageImages/Janszon_logo_dark.png',
  '/images/MarketingPageImages/edmij_logo_dark.png',
];

// Modify the base logo styles to remove margin (we'll handle it in the Grid)
const logoStyles = [
  {
    // YesDelft logo style
    width: '200px',
    height: '160px',
    opacity: 0.8,
  },
  {
    // Sungrow logo style
    width: '300px',
    height: '240px',
    opacity: 0.8,
  },
  {
    // Janszon logo style
    width: '150px',
    height: '120px',
    opacity: 0.8,
  },
  {
    // EDMIJ logo style
    width: '200px',
    height: '100px',
  },
];

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Trusted by
      </Typography>
      <Grid container sx={{ 
        justifyContent: 'center', 
        alignItems: 'center',
        mt: 0.5 
      }}>
        {logos.map((logo, index) => (
          <Grid item key={index} sx={{ 
            display: 'flex',
            alignItems: 'center',
            mx: 5, // Add horizontal margin to all logos
            ...(index === 3 && {  // Special styling for EDMIJ logo (index 3)
              mt: {
                xs: 15,  // 15 * 8 = 120px on mobile
                sm: 0    // 0px on tablet and up
              }
            })
          }}>
            <img
              src={logo}
              alt={`Company logo ${index + 1}`}
              style={logoStyles[index]}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
