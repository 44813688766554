// Custom date-time range picker component for the dashboard

import * as React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function ButtonField(props) {
  const {
    setOpen,
    id,
    disabled,
    startDate,
    endDate,
    InputProps: { ref } = {},
    inputProps: { 'aria-label': ariaLabel } = {},
  } = props;

  const formatDateTime = (date) => {
    return date ? date.format('MMM DD, YYYY HH:mm') : 'Select';
  };

  return (
    <Button
      variant="outlined"
      id={id}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      size="small"
      onClick={() => setOpen?.((prev) => !prev)}
      startIcon={<CalendarTodayRoundedIcon fontSize="small" />}
      sx={{ minWidth: 'fit-content' }}
    >
      {`${formatDateTime(startDate)} - ${formatDateTime(endDate)}`}
    </Button>
  );
}

ButtonField.propTypes = {
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputProps: PropTypes.shape({
    'aria-label': PropTypes.string,
  }),
  InputProps: PropTypes.shape({
    ref: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  }),
  setOpen: PropTypes.func,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
};

export default function CustomDateTimePicker({ 
  onDateRangeChange,
  initialStartDate = dayjs().subtract(24, 'hour'),
  initialEndDate = dayjs()
}) {
  const [startDate, setStartDate] = React.useState(initialStartDate);
  const [endDate, setEndDate] = React.useState(initialEndDate);
  const [open, setOpen] = React.useState(false);

  const handleApply = () => {
    if (onDateRangeChange) {
      onDateRangeChange({ startDate, endDate });
    }
    setOpen(false);
  };

  const handleStartDateChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setStartDate(newValue);
    }
  };

  const handleEndDateChange = (newValue) => {
    if (newValue && newValue.isValid()) {
      setEndDate(newValue);
    }
  };

  const handlePredefinedRange = (range) => {
    let newStartDate;
    let newEndDate = dayjs();

    switch (range) {
      case 'today':
        newStartDate = dayjs().startOf('day');
        break;
      case 'twoDays':
        newStartDate = dayjs().subtract(1, 'day').startOf('day');
        break;
      case 'month':
        newStartDate = dayjs().subtract(1, 'month').startOf('day');
        break;
      default:
        return;
    }

    setStartDate(newStartDate);
    setEndDate(newEndDate);
    
    if (onDateRangeChange) {
      onDateRangeChange({ startDate: newStartDate, endDate: newEndDate });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* Predefined Range Buttons */}
        <Button 
          variant="outlined" 
          size="small"
          onClick={() => handlePredefinedRange('today')}
        >
          Today
        </Button>
        <Button 
          variant="outlined" 
          size="small"
          onClick={() => handlePredefinedRange('twoDays')}
        >
          Last 2 Days
        </Button>
        <Button 
          variant="outlined" 
          size="small"
          onClick={() => handlePredefinedRange('month')}
        >
          Last Month
        </Button>

        {/* Date Picker */}
        <Box sx={{ position: 'relative' }}>
          <ButtonField
            setOpen={setOpen}
            startDate={startDate}
            endDate={endDate}
          />
          {open && (
            <Box
              sx={{
                position: 'absolute',
                top: '100%',
                right: 0,
                mt: 1,
                zIndex: 1000,
                bgcolor: 'background.paper',
                borderRadius: 1,
                boxShadow: 3,
                p: 2,
                minWidth: '300px',
              }}
            >
              <Stack spacing={2}>
                {/* Custom Date Range Pickers */}
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    From
                  </Typography>
                  <DateTimePicker
                    value={startDate}
                    onChange={handleStartDateChange}
                    maxDateTime={endDate}
                    slotProps={{
                      textField: { size: 'small' },
                      nextIconButton: { size: 'small' },
                      previousIconButton: { size: 'small' },
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="subtitle2" gutterBottom>
                    To
                  </Typography>
                  <DateTimePicker
                    value={endDate}
                    onChange={handleEndDateChange}
                    minDateTime={startDate}
                    slotProps={{
                      textField: { size: 'small' },
                      nextIconButton: { size: 'small' },
                      previousIconButton: { size: 'small' },
                    }}
                  />
                </Box>
                <Button 
                  variant="contained" 
                  onClick={handleApply}
                  size="small"
                >
                  Apply Custom Range
                </Button>
              </Stack>
            </Box>
          )}
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}

CustomDateTimePicker.propTypes = {
  onDateRangeChange: PropTypes.func,
  initialStartDate: PropTypes.object,
  initialEndDate: PropTypes.object,
};
