// Widget for the company profile in the user home page (is integrated in the main grid)

import * as React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import BusinessIcon from '@mui/icons-material/Business';

export default function WidgetCompanyProfile({ companyProfile }) {
  return (
    <>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Welcome back, {companyProfile.name}
      </Typography>
      <Card variant="outlined">
        <CardContent>
          <Stack spacing={2} alignItems="center">
            <Avatar
              src={companyProfile.avatar}
              sx={{ width: 80, height: 80 }}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <BusinessIcon fontSize="small" />
              <Typography variant="body2">{companyProfile.name}</Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <LocationOnIcon fontSize="small" />
              <Typography variant="body2">{companyProfile.address}</Typography>
            </Stack>
            <Button
              startIcon={<VisibilityIcon />}
              variant="outlined"
              fullWidth
            >
              View Rooftop
            </Button>
            <Box
              component="img"
              src={companyProfile.rooftopImage}
              sx={{
                width: '100%',
                borderRadius: 1,
                aspectRatio: '16/9',
                objectFit: 'cover'
              }}
            />
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}

WidgetCompanyProfile.propTypes = {
  companyProfile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    avatar: PropTypes.string.isRequired,
    rooftopImage: PropTypes.string.isRequired
  }).isRequired
}; 