import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SaveIcon from '@mui/icons-material/Save';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const WidgetSimulationControls = () => {
  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        startIcon={<PlayArrowIcon />}
      >
        Run Simulation
      </Button>
      <Button
        variant="outlined"
        startIcon={<SaveIcon />}
      >
        Save Changes
      </Button>
      <Button
        variant="outlined"
        startIcon={<CompareArrowsIcon />}
      >
        Compare Scenarios
      </Button>
      <Button
        variant="outlined"
        startIcon={<FileDownloadIcon />}
      >
        Export Results
      </Button>
    </Stack>
  );
};

export default WidgetSimulationControls; 