// Main grid component, serves as the main content area for the user interface.
// It includes widgets such as pie charts, nested trees, data tables, 
// cards with buttons, bar charts, and timeseries charts.

import * as React from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Copyright from '../internals/components/Copyright';
import WidgetAccountManager from './WidgetAccountManager';
import WidgetCompanyProfile from './WidgetCompanyProfile';
import WidgetEnergyProfile from './WidgetEnergyProfile';
import WidgetTimeseriesMonitoring from './WidgetTimeseriesMonitoring';
import WidgetMarketplace from './WidgetMarketplace';
import WidgetEnergyInsights from './WidgetEnergyInsights';

const companyProfile = {
  name: 'Tess B.V.',
  address: 'Vondelweg 246',
  avatar: '/images/UserInterfaceImages/tess_profile.jpeg',
  rooftopImage: '/images/UserInterfaceImages/building_rooftop.jpg'
};

const marketplaceOffers = [
  {
    title: 'Janszon Offer',
    description: 'Premium solar panel installation with 25-year warranty.',
    recommendation: 'This offer makes you more independent and green with more upfront investment.'
  },
  {
    title: 'Installer II Offer',
    description: 'Comprehensive energy efficiency upgrade package.',
    warning: 'While this package offers some benefits, it may not be the most cost-effective solution for your specific needs.'
  },
  {
    title: 'EcoSmart Deal',
    description: 'Smart home energy management system with AI optimization.',
    recommendation: 'Given your interest in detailed monitoring, this system could provide valuable insights and automate your energy usage.'
  }
];

export default function MainGrid() {
  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
      <Grid container spacing={3}>
        {/* Welcome and Company Profile Section */}
        <Grid size={{ xs: 12, md: 3 }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <WidgetCompanyProfile companyProfile={companyProfile} />
          </Box>
        </Grid>

        {/* Energy Insights Section */}
        <Grid size={{ xs: 12, md: 6 }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <WidgetEnergyInsights />
          </Box>
        </Grid>

        {/* Account Manager Section */}
        <Grid size={{ xs: 12, md: 3 }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <WidgetAccountManager />
          </Box>
        </Grid>

        {/* Energy Profile Section */}
        <Grid size={{ xs: 12, md: 4 }}>
          <WidgetEnergyProfile />
        </Grid>

        {/* Monitoring Section */}
        <Grid size={{ xs: 12, md: 8 }}>
          <WidgetTimeseriesMonitoring />
        </Grid>

        {/* Marketplace Section */}
        <Grid size={{ xs: 12 }}>
          <WidgetMarketplace offers={marketplaceOffers} />
        </Grid>
      </Grid>
      
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
