import React, { useState } from 'react';
import { 
  Grid, 
  Paper, 
  TextField, 
  Typography, 
  Button, 
  Card,
  CardContent,
  Stack,
  IconButton,
  Box,
  CircularProgress,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PaidIcon from '@mui/icons-material/Paid';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PropTypes from 'prop-types';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { alpha } from '@mui/material/styles';
import CreateAssetDialog from '../../asset-library/components/CreateAssetDialog';
import { useNavigate } from 'react-router-dom';
import SIMULATION_LIST from '../../existing-simulation/data/SimulationList';
import { getModelInstances } from '../../../../../services/api';

const ASSET_TYPES = {
  PV: {
    name: 'PV Panel',
    icon: <SolarPowerIcon />,
    libraryKey: 'pv',
    params: {
      power: { default: '0.32 kW' },
      efficiency: { default: '20%' },
      warranty: { default: '25 years' }
    }
  },
  STORAGE: {
    name: 'Battery',
    icon: <BatteryChargingFullIcon />,
    libraryKey: 'storage',
    params: {
      capacity: { default: '10 kWh' },
      power: { default: '5 kW' },
      warranty: { default: '10 years' }
    }
  },
  LOAD: {
    name: 'Load Profile',
    icon: <HomeIcon />,
    libraryKey: 'loads',
    params: {
      peakPower: { default: '7.5 kW' },
      dailyConsumption: { default: '45 kWh' }
    }
  },
  TARIFF: {
    name: 'Tariff',
    icon: <PaidIcon />,
    libraryKey: 'tariffs',
    params: {
      peakRate: { default: '0.30 €/kWh' },
      offPeakRate: { default: '0.15 €/kWh' }
    }
  },
  CONTROLLER: {
    name: 'Controller',
    icon: <SettingsIcon />,
    libraryKey: 'controllers',
    params: {
      algorithm: { default: 'Peak Shaving' },
      updateRate: { default: '15 min' }
    }
  }
};

// Define drag type constant
const ASSET_DRAG_TYPE = 'asset';

// Create a draggable asset component
const DraggableAssetIcon = ({ type, config }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: ASSET_DRAG_TYPE,
    item: { type },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  return (
    <Box
      ref={drag}
      sx={(theme) => ({
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
        p: 3,
        border: '1px solid',
        borderColor: theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        gap: 2,
        backgroundColor: theme.palette.background.paper,
        transition: 'all 0.3s ease',
        '&:hover': {
          bgcolor: theme.palette.action.hover,
          transform: 'translateY(-3px)',
          boxShadow: '0 8px 16px rgba(0,0,0,0.1)',
        },
      })}
    >
      <Box sx={(theme) => ({ 
        color: theme.palette.primary.main,
        transform: 'scale(1.2)'
      })}>
        {config.icon}
      </Box>
      <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>{config.name}</Typography>
    </Box>
  );
};

DraggableAssetIcon.propTypes = {
  type: PropTypes.string.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
  }).isRequired,
};

// Move AssetDropZone component definition here
const AssetDropZone = ({ 
  onDrop, 
  assets, 
  expandedAssets,
  handleAssetExpand,
  handleDeleteAsset, 
  handleAssetParamChange,
  onAssetModelSelect
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ASSET_DRAG_TYPE,
    drop: (item) => onDrop(item.type),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  const [modelInstances, setModelInstances] = React.useState({});
  const [loadingInstances, setLoadingInstances] = React.useState({});
  const [selectedLibraryAsset, setSelectedLibraryAsset] = React.useState({});
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);

  // Function to fetch model instances for a specific asset type
  const fetchModelInstances = async (assetType) => {
    const typeMap = {
      PV: 'pv',
      STORAGE: 'battery',
      LOAD: 'load',
      CONTROLLER: 'controller',
      TARIFF: 'tariff'
    };

    const type = typeMap[assetType];
    if (!type) return;

    try {
      setLoadingInstances(prev => ({ ...prev, [assetType]: true }));
      const instances = await getModelInstances(type, 'all');
      setModelInstances(prev => ({ ...prev, [assetType]: instances }));
    } catch (error) {
      console.error(`Error fetching ${type} instances:`, error);
    } finally {
      setLoadingInstances(prev => ({ ...prev, [assetType]: false }));
    }
  };

  // Fetch instances when a new asset is added
  React.useEffect(() => {
    assets.forEach(asset => {
      if (!modelInstances[asset.type]) {
        fetchModelInstances(asset.type);
      }
    });
  }, [assets]);

  const handleModelInstanceSelect = (assetId, instance) => {
    setSelectedLibraryAsset(prev => ({
      ...prev,
      [assetId]: instance.id
    }));
    
    // Create the asset data for scenario creation
    const assetData = {
      id: instance.id,
      type: instance.type,
      params: instance.params,
      name: instance.name
    };

    // Pass the selected model data up to parent
    onAssetModelSelect(assetId, assetData);
    
    // Update the asset parameters based on the selected model instance
    if (instance.params) {
      Object.entries(instance.params).forEach(([key, value]) => {
        handleAssetParamChange(assetId, key, value.toString());
      });
    }
    handleAssetExpand(assetId);
  };

  const handleNewAssetCreated = (newAsset) => {
    console.log('New asset created:', newAsset);
    setCreateDialogOpen(false);
  };

  return (
    <Box
      ref={drop}
      sx={(theme) => ({
        height: '100%',
        minHeight: 400,
        border: '3px dashed',
        borderColor: isOver ? theme.palette.primary.main : theme.palette.divider,
        borderRadius: 3,
        display: 'flex',
        flexDirection: 'column',
        bgcolor: isOver ? alpha(theme.palette.primary.main, 0.05) : theme.palette.background.default,
        transition: 'all 0.3s',
        p: 4,
        '&:hover': {
          borderColor: theme.palette.primary.main,
          bgcolor: alpha(theme.palette.primary.main, 0.02),
        },
      })}
    >
      <Box sx={{ textAlign: 'center', mb: 3 }}>
        <Typography variant="h6" gutterBottom>
          Assemble your scenario
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {isOver ? 'Drop it here!' : 'Drag and drop assets here to build your scenario'}
        </Typography>
      </Box>

      <Stack spacing={2}>
        {assets.map((asset) => (
          <Card key={asset.id} variant="outlined" sx={{ borderRadius: 2, transition: 'all 0.2s ease', '&:hover': { boxShadow: '0 4px 8px rgba(0,0,0,0.1)', } }}>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {ASSET_TYPES[asset.type].icon}
                    <Stack>
                      <Typography>{ASSET_TYPES[asset.type].name}</Typography>
                      {selectedLibraryAsset[asset.id] && !expandedAssets[asset.id] && (
                        <Typography 
                          variant="body2" 
                          color="text.secondary"
                          sx={{ mt: 0.5 }}
                        >
                          Selected Model: {modelInstances[asset.type]?.find(
                            instance => instance.id === selectedLibraryAsset[asset.id]
                          )?.name || ''}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                  <Stack direction="row" spacing={1}>
                    <IconButton
                      size="small"
                      onClick={() => handleAssetExpand(asset.id)}
                      color="primary"
                    >
                      <ExpandMoreIcon 
                        sx={{ 
                          transform: expandedAssets[asset.id] ? 'rotate(180deg)' : 'none',
                          transition: 'transform 0.2s'
                        }}
                      />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteAsset(asset.id)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Stack>
                </Stack>

                <Collapse in={expandedAssets[asset.id]}>
                  <Stack spacing={2} sx={{ mt: 2 }}>
                    {loadingInstances[asset.type] ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress size={24} />
                      </Box>
                    ) : (
                      <>
                        <Typography variant="subtitle2" color="text.secondary">
                          Select a model:
                        </Typography>
                        <Grid container spacing={2}>
                          {modelInstances[asset.type]?.map((instance) => (
                            <Grid item xs={12} sm={6} key={instance.id}>
                              <Card 
                                variant="outlined"
                                sx={(theme) => ({
                                  cursor: 'pointer',
                                  bgcolor: selectedLibraryAsset[asset.id] === instance.id ? 
                                    `${theme.palette.primary.light}20` : 'background.paper',
                                  borderColor: selectedLibraryAsset[asset.id] === instance.id ?
                                    'primary.main' : 'divider',
                                  '&:hover': {
                                    bgcolor: selectedLibraryAsset[asset.id] === instance.id ?
                                      `${theme.palette.primary.light}30` : 'action.hover',
                                  },
                                  transition: 'all 0.2s'
                                })}
                                onClick={() => handleModelInstanceSelect(asset.id, instance)}
                              >
                                <CardContent>
                                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <Typography variant="subtitle1">{instance.name}</Typography>
                                    {selectedLibraryAsset[asset.id] === instance.id && (
                                      <CheckCircleIcon color="primary" sx={{ fontSize: 20 }} />
                                    )}
                                  </Stack>
                                  <Stack spacing={1} mt={1}>
                                    {Object.entries(instance.params).map(([key, value]) => (
                                      <Typography key={key} variant="body2" color="text.secondary">
                                        {key}: {value}
                                      </Typography>
                                    ))}
                                  </Stack>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </>
                    )}
                  </Stack>
                </Collapse>
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <CreateAssetDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSubmit={handleNewAssetCreated}
        category={{
          id: '',
          name: '',
          description: ''
        }}
      />
    </Box>
  );
};

// Update PropTypes
AssetDropZone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  assets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  })).isRequired,
  expandedAssets: PropTypes.object.isRequired,
  handleAssetExpand: PropTypes.func.isRequired,
  handleDeleteAsset: PropTypes.func.isRequired,
  handleAssetParamChange: PropTypes.func.isRequired,
  onAssetModelSelect: PropTypes.func.isRequired
};

// Add display name for better debugging
AssetDropZone.displayName = 'AssetDropZone';

const NewSimulationGrid = () => {
  const [simulationData, setSimulationData] = useState({
    name: '',
    client: '',
    scenarios: []
  });
  const [openScenarioId, setOpenScenarioId] = useState(null);
  const [expandedAssets, setExpandedAssets] = useState({});
  const [selectedModels, setSelectedModels] = useState({});
  const navigate = useNavigate();

  const handleAddScenario = () => {
    const newId = Date.now();
    setSimulationData(prev => ({
      ...prev,
      scenarios: [...prev.scenarios, {
        id: newId,
        name: `Scenario ${prev.scenarios.length + 1}`,
        configuration: {
          assets: []
        }
      }]
    }));
    setOpenScenarioId(newId);
  };

  const handleDeleteScenario = (scenarioId) => {
    setSimulationData(prev => ({
      ...prev,
      scenarios: prev.scenarios.filter(scenario => scenario.id !== scenarioId)
    }));
  };

  const handleAssetModelSelect = (assetId, modelData) => {
    setSelectedModels(prev => ({
      ...prev,
      [assetId]: modelData
    }));
  };

  const handleCreateSimulation = () => {
    // Format the path from simulation name
    const simulationPath = simulationData.name
      .toLowerCase()
      .replace(/[^a-z0-9]/g, '')
      .trim();

    // Create scenarios with selected model data
    const scenariosWithModels = simulationData.scenarios.map(scenario => ({
      ...scenario,
      configuration: {
        ...scenario.configuration,
        assets: scenario.configuration.assets.map(asset => ({
          ...asset,
          modelData: selectedModels[asset.id] // Include the selected model data
        }))
      }
    }));

    // Create new simulation entry
    const newSimulation = {
      id: simulationPath,
      simulationName: simulationData.name,
      clientName: simulationData.client,
      lastUpdate: new Date().toLocaleString(),
      scenarios: scenariosWithModels
    };

    // Add to SIMULATION_LIST
    SIMULATION_LIST[simulationPath] = newSimulation;

    // Navigate to the new simulation
    navigate(`/simulator/${simulationPath}`);
  };

  const handleScenarioExpand = (scenarioId) => {
    setOpenScenarioId(openScenarioId === scenarioId ? null : scenarioId);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
        <Box sx={{ width: '100%', mt: 6 }}>
          <Grid container spacing={3} component="form">
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ 
                p: 5,
                bgcolor: 'background.paper',
                borderRadius: 3,
                boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
              }}>
                <Typography 
                  variant="h5"
                  gutterBottom
                  sx={{ 
                    fontWeight: 600,
                    mb: 3
                  }}
                >
                  Simulation Details
                </Typography>
                <TextField
                  id="simulation-name"
                  name="simulation-name"
                  fullWidth
                  label="Simulation Name"
                  value={simulationData.name}
                  onChange={(e) => setSimulationData({ ...simulationData, name: e.target.value })}
                  margin="normal"
                  aria-label="Simulation Name"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 2,
                      backgroundColor: 'background.default',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      },
                    }
                  }}
                />
              </Paper>

              <Paper elevation={3} sx={{ 
                p: 4,
                mt: 4,
                bgcolor: 'background.paper',
                borderRadius: 3
              }}>
                <Typography 
                  variant="h5"
                  gutterBottom
                  sx={{ 
                    fontWeight: 600,
                    mb: 3
                  }}
                >
                  Scenarios
                </Typography>
                
                <Stack spacing={3} sx={{ mb: 3 }}>
                  {simulationData.scenarios.map((scenario) => (
                    <Card key={scenario.id} variant="outlined">
                      <CardContent>
                        <Stack spacing={2}>
                          <Stack 
                            direction="row" 
                            justifyContent="space-between" 
                            alignItems="center"
                          >
                            <Stack direction="row" spacing={1} alignItems="center">
                              <Typography variant="subtitle1">
                                {scenario.name}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                              <IconButton
                                size="small"
                                onClick={() => handleScenarioExpand(scenario.id)}
                                color="primary"
                              >
                                <ExpandMoreIcon 
                                  sx={{ 
                                    transform: openScenarioId === scenario.id ? 'rotate(180deg)' : 'none',
                                    transition: 'transform 0.2s'
                                  }}
                                />
                              </IconButton>
                              {openScenarioId === scenario.id && (
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    // Close the scenario when confirmed
                                    setOpenScenarioId(null);
                                  }}
                                  color="success"
                                  aria-label="Confirm scenario configuration"
                                >
                                  <CheckCircleIcon />
                                </IconButton>
                              )}
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteScenario(scenario.id)}
                                color="error"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Stack>
                          </Stack>

                          <Collapse in={openScenarioId === scenario.id}>
                            <Stack spacing={2}>
                              <TextField
                                fullWidth
                                label="Scenario Name"
                                value={scenario.name}
                                onChange={(e) => {
                                  setSimulationData(prev => ({
                                    ...prev,
                                    scenarios: prev.scenarios.map(s => 
                                      s.id === scenario.id 
                                        ? { ...s, name: e.target.value }
                                        : s
                                    )
                                  }));
                                }}
                              />

                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <Paper elevation={3} sx={{ p: 2 }}>
                                    <Typography variant="h6" gutterBottom>
                                      Available Assets
                                    </Typography>
                                    <Stack 
                                      direction="row" 
                                      spacing={2} 
                                      sx={{ 
                                        flexWrap: 'wrap', 
                                        gap: 2,
                                        mt: 2 
                                      }}
                                    >
                                      {Object.entries(ASSET_TYPES).map(([type, config]) => (
                                        <DraggableAssetIcon key={type} type={type} config={config} />
                                      ))}
                                    </Stack>
                                  </Paper>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                  <Paper 
                                    elevation={3} 
                                    sx={{ 
                                      height: '100%',
                                      minHeight: 400,
                                    }}
                                  >
                                    <AssetDropZone 
                                      onDrop={(type) => {
                                        const newAssetId = Date.now();
                                        setSimulationData(prev => ({
                                          ...prev,
                                          scenarios: prev.scenarios.map(s => 
                                            s.id === openScenarioId 
                                              ? {
                                                  ...s,
                                                  configuration: {
                                                    ...s.configuration,
                                                    assets: [...s.configuration.assets, {
                                                      id: newAssetId,
                                                      type,
                                                      params: Object.entries(ASSET_TYPES[type].params)
                                                        .reduce((acc, [key, value]) => {
                                                          acc[key] = value.default;
                                                          return acc;
                                                        }, {})
                                                    }]
                                                  }
                                                }
                                              : s
                                          )
                                        }));
                                        setExpandedAssets(prev => ({
                                          ...prev,
                                          [newAssetId]: true
                                        }));
                                      }}
                                      assets={simulationData.scenarios.find(s => s.id === openScenarioId)?.configuration.assets || []}
                                      expandedAssets={expandedAssets}
                                      handleAssetExpand={(assetId) => {
                                        setExpandedAssets(prev => ({
                                          ...prev,
                                          [assetId]: !prev[assetId]
                                        }));
                                      }}
                                      handleDeleteAsset={(assetId) => {
                                        setSimulationData(prev => ({
                                          ...prev,
                                          scenarios: prev.scenarios.map(s => 
                                            s.id === openScenarioId 
                                              ? {
                                                  ...s,
                                                  configuration: {
                                                    ...s.configuration,
                                                    assets: s.configuration.assets.filter(a => a.id !== assetId)
                                                  }
                                                }
                                              : s
                                          )
                                        }));
                                        setExpandedAssets(prev => {
                                          const newState = { ...prev };
                                          delete newState[assetId];
                                          return newState;
                                        });
                                      }}
                                      handleAssetParamChange={(assetId, paramName, value) => {
                                        setSimulationData(prev => ({
                                          ...prev,
                                          scenarios: prev.scenarios.map(s => 
                                            s.id === openScenarioId 
                                              ? {
                                                  ...s,
                                                  configuration: {
                                                    ...s.configuration,
                                                    assets: s.configuration.assets.map(a => 
                                                      a.id === assetId 
                                                        ? { ...a, params: { ...a.params, [paramName]: value } }
                                                      : a
                                                    )
                                                  }
                                                }
                                              : s
                                          )
                                        }));
                                      }}
                                      onAssetModelSelect={handleAssetModelSelect}
                                    />
                                  </Paper>
                                </Grid>
                              </Grid>
                            </Stack>
                          </Collapse>
                        </Stack>
                      </CardContent>
                    </Card>
                  ))}
                </Stack>

                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={handleAddScenario}
                  fullWidth
                  sx={{
                    borderRadius: 3,
                    p: 2,
                    borderStyle: 'dashed',
                    borderWidth: 2,
                    fontSize: '1rem',
                    fontWeight: 500,
                    '&:hover': {
                      borderStyle: 'dashed',
                      backgroundColor: 'action.hover',
                      transform: 'translateY(-2px)',
                      transition: 'all 0.3s ease',
                    },
                  }}
                >
                  Add Scenario
                </Button>
              </Paper>

              <Stack alignItems="center" sx={{ mt: 5, mb: 4 }}>
                <Button
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={handleCreateSimulation}
                  sx={{ 
                    height: '56px',
                    width: '240px',
                    borderRadius: 3,
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    textTransform: 'none',
                    boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
                    '&:hover': {
                      boxShadow: '0 8px 16px rgba(0,0,0,0.15)',
                      transform: 'translateY(-3px)',
                    },
                    transition: 'all 0.3s ease',
                  }}
                >
                  Save Simulation
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </DndProvider>
  );
};

export default NewSimulationGrid; 