const SIMULATION_LIST = {
  simplana: {
    id: 'simplana',
    simulationName: 'Sim Plan A',
    clientName: 'Tess B.V.',
    lastUpdate: '20/03/2024 14:30',
    scenarios: [
      {
        id: 1,
        name: 'Scenario 1',
        configuration: {
          assets: [
            {
              id: 1,
              type: 'PV',
              params: {
                power: '400W',
                efficiency: '21.3%',
                warranty: '25 years'
              }
            }
          ]
        }
      }
    ]
  }
};

export default SIMULATION_LIST; 