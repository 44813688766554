import * as React from 'react';
import UserSimulator from './UserSimulator';
import ExistingSimulation from './existing-simulation/ExistingSimulation';
import NewSimulation from './new-simulation/NewSimulation';

export const simulatorRoutes = [
  {
    path: "/simulator/new-simulation",
    element: <NewSimulation />,
  },
  {
    path: "/simulator/:simulationId",
    element: <ExistingSimulation />,
  },
  {
    path: "/simulator",
    element: <UserSimulator />,
  },
];

export default simulatorRoutes; 