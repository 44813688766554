import * as React from 'react';
import { alpha } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import WidgetMobileNavbar from '../../common-components/WidgetMobileNavbar';
import Header from '../../common-components/Header';
import SideMenu from '../../common-components/SideMenu';
import AppTheme from '../../../shared-theme/AppTheme';
import ExistingSimulationGrid from './components/ExistingSimulationGrid';
import simulationService from '../../../../services/simulationService';
import { Alert, CircularProgress } from '@mui/material';
import {
  chartsCustomizations,
  dataTableCustomizations,
  nestedTreeCustomizations,
} from '../../user-home/theme_customizations';

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataTableCustomizations,
  ...nestedTreeCustomizations,
};

export default function ExistingSimulation(props) {
  const { simulationId } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(null);
  const [simulationData, setSimulationData] = React.useState(null);

  React.useEffect(() => {
    const fetchSimulation = async () => {
      setLoading(true);
      const result = await simulationService.getSimulation(simulationId);

      if (result.success) {
        setSimulationData({
          simulationName: result.data.name,
          status: result.data.status,
          createdAt: new Date(result.data.created_at).toLocaleString(),
          startedAt: result.data.started_at ? new Date(result.data.started_at).toLocaleString() : null,
          completedAt: result.data.completed_at ? new Date(result.data.completed_at).toLocaleString() : null,
          errorMessage: result.data.error_message
        });
        setError(null);
      } else {
        setError(result.error);
        if (result.error === 'Simulation not found') {
          // Optionally redirect to simulations list after a delay
          setTimeout(() => navigate('/simulator'), 3000);
        }
      }
      setLoading(false);
    };

    fetchSimulation();
  }, [simulationId, navigate]);

  if (loading) {
    return (
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      </AppTheme>
    );
  }

  if (error) {
    return (
      <AppTheme {...props} themeComponents={xThemeComponents}>
        <Box sx={{ p: 3 }}>
          <Alert severity="error">{error}</Alert>
        </Box>
      </AppTheme>
    );
  }

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <WidgetMobileNavbar />
        <Box
          component="main"
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.vars
              ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
              : alpha(theme.palette.background.default, 1),
            overflow: 'auto',
          })}
        >
          <Stack
            spacing={2}
            sx={{
              alignItems: 'flex-start',
              mx: 3,
              pb: 5,
              mt: { xs: 8, md: 0 },
              width: '100%',
              maxWidth: { sm: '100%', md: '1700px' }
            }}
          >
            <Header />
            {simulationData && <ExistingSimulationGrid simulationData={simulationData} />}
          </Stack>
        </Box>
      </Box>
    </AppTheme>
  );
}
