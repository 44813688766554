// Widget for the stat card: card with a sparkline chart and a value, trend and interval
// (is used in the WidgetEnergyInsights widget to show the energy insights)

import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';

function getDaysInMonth(month, year) {
  const date = new Date(year, month, 0);
  const monthName = date.toLocaleDateString('en-US', {
    month: 'short',
  });
  const daysInMonth = date.getDate();
  const days = [];
  let i = 1;
  while (days.length < daysInMonth) {
    days.push(`${monthName} ${i}`);
    i += 1;
  }
  return days;
}

function StatCard({ title, value, interval, trend, data, icon, change, customColor }) {
  const theme = useTheme();
  const daysInWeek = getDaysInMonth(4, 2024);

  const trendColors = {
    up:
      customColor || (theme.palette.mode === 'light'
        ? theme.palette.success.main
        : theme.palette.success.dark),
    down:
      theme.palette.mode === 'light'
        ? theme.palette.error.main
        : theme.palette.error.dark,
    neutral:
      customColor || (theme.palette.mode === 'light'
        ? theme.palette.grey[400]
        : theme.palette.grey[700]),
  };

  const labelColors = {
    up: 'success',
    down: 'error',
    neutral: 'default',
  };

  const color = labelColors[trend];
  const chartColor = trendColors[trend];

  return (
    <Card variant="outlined" sx={{ height: '100%', flexGrow: 1 }}>
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 1 }}>
          {icon && (
            <Box sx={{ 
              color: theme.palette.primary.main,
              display: 'flex',
              alignItems: 'center' 
            }}>
              {icon}
            </Box>
          )}
          <Typography component="h2" variant="subtitle2">
            {title}
          </Typography>
        </Stack>
        <Stack
          direction="column"
          sx={{ justifyContent: 'space-between', flexGrow: '1', gap: 1 }}
        >
          <Typography variant="h4" component="p">
            {value}
          </Typography>
          
          <Stack
            direction="row"
            sx={{ 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 1
            }}
          >
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {interval}
            </Typography>
            {change && <Chip size="small" color={color} label={change} />}
          </Stack>

          <Box sx={{ width: '100%', height: 50 }}>
            <SparkLineChart
              colors={[chartColor]}
              data={data}
              area={false}
              showHighlight
              showTooltip
              xAxis={{
                scaleType: 'band',
                data: daysInWeek,
              }}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}

StatCard.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  interval: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  trend: PropTypes.oneOf(['down', 'neutral', 'up']).isRequired,
  value: PropTypes.string.isRequired,
  icon: PropTypes.node,
  change: PropTypes.string,
  customColor: PropTypes.string,
};

export default StatCard;