// Footer component, serves as the footer of the marketing page
// It includes a contact form, a set of social media links, and a copyright statement.  

import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
//import InputLabel from '@mui/material/InputLabel';
//import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
// import FacebookIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
// import TwitterIcon from '@mui/icons-material/X';
import { styled } from '@mui/system';
import { contactService } from '../../../services/api';

// function Copyright() {
//   return (
//     <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
//       {'Copyright © '}
//       <Link color="text.secondary" href="https://mui.com/">
//         Vems
//       </Link>
//       &nbsp;
//       {new Date().getFullYear()}
//     </Typography>
//   );
// }

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    fontFamily: "'IBM Plex Sans', sans-serif",
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: 1.5,
    padding: '8px 12px',
    borderRadius: '8px',
    color: theme.palette.mode === 'dark' ? '#D1D5DB' : '#111827',
    backgroundColor: theme.palette.mode === 'dark' ? '#1F2937' : '#fff',
    border: `1px solid ${theme.palette.mode === 'dark' ? '#374151' : '#D1D5DB'}`,
    boxShadow: `0px 2px 2px ${theme.palette.mode === 'dark' ? '#111827' : '#F3F4F6'}`,

    '&:hover': {
      borderColor: theme.palette.primary.main,
    },

    '&.Mui-focused': {
      outline: 0,
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 3px ${theme.palette.mode === 'dark' ? theme.palette.primary.dark : theme.palette.primary.light}`,
    },
  },
}));

export default function Footer() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    try {
      const response = await contactService.sendMessage(formData);
      if (response.success) {
        setStatus('Message sent successfully!');
        setFormData({ name: '', email: '', message: '' });
      } else {
        throw new Error(response.data.message || 'Failed to send message');
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setStatus(error.response?.data?.message || 'Failed to send message. Please try again later.');
    }
  };

  return (
    <Container
      id="contactus"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: 'auto' },
            maxWidth: '600px',
            width: '100%',
          }}
        >
          <Box sx={{ 
            width: '100%',
            flexDirection: 'column',
            margin: '0 auto',
          }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
              <Box sx={{ width: 'fit-content', display: 'flex', justifyContent: 'center', mb: 2 }}>
                <img 
                  src="/images/GeneralImages/vems_logo_dark.png"
                  alt="Company Logo" 
                  style={{ 
                    width: '100%', 
                    height: 'auto', 
                    maxWidth: '120px'
                  }} 
                />
              </Box>
              <Typography 
                variant="h5" 
                sx={(theme) => ({
                  mt: 2, 
                  mb: 2, 
                  fontSize: '1.8rem', 
                  fontWeight: 'bold',
                  color: theme.palette.mode === 'dark' ? theme.palette.grey[200] : theme.palette.grey[800]
                })}
              >
                Contact us
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} autoComplete="on">
              <Stack spacing={2}>
                <StyledTextField
                  id="contact-name"
                  name="name"
                  fullWidth
                  label="Name"
                  variant="outlined"
                  required
                  value={formData.name}
                  onChange={handleInputChange}
                  autoComplete="name"
                  inputProps={{
                    autoCapitalize: "words"
                  }}
                />
                <StyledTextField
                  id="contact-email"
                  name="email"
                  fullWidth
                  label="Email"
                  variant="outlined"
                  type="email"
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                  autoComplete="email"
                />
                <StyledTextField
                  id="contact-message"
                  name="message"
                  fullWidth
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={8}
                  required
                  value={formData.message}
                  onChange={handleInputChange}
                  autoComplete="off"
                  sx={{
                    '& .MuiInputBase-root': {
                      height: 'auto',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      height: 'auto',
                    },
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ mt: 2 }}
                >
                  Send Message
                </Button>
              </Stack>
            </form>
            {status && (
              <Typography 
                sx={{ 
                  mt: 2, 
                  color: status.includes('success') ? 'success.main' : 
                         status === 'Sending...' ? 'primary.main' :
                         'error.main' 
                }}
              >
                {status}
              </Typography>
            )}
          </Box>
        </Box>
        {/* <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Product
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Features
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Testimonials
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Highlights
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Pricing
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            FAQs
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Company
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            About us
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Careers
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Press
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
            Legal
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Privacy
          </Link>
          <Link color="text.secondary" variant="body2" href="#">
            Contact
          </Link>
        </Box> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        {/* <div>
          <Link color="text.secondary" variant="body2" href="#">
            Privacy Policy
          </Link>
          <Typography sx={{ display: 'inline', mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="#">
            Terms of Service
          </Link>
          <Copyright />
        </div> */}
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ 
            width: '100%',
            justifyContent: 'space-between', 
            color: 'text.secondary' 
          }}
        >
          <Box>
            <Typography 
              variant="h6"
              sx={{ 
                fontWeight: 'bold',
                color: (theme) => theme.palette.text.primary,
                mb: 1
              }}
            >
              Follow us on
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}> {/* Added margin-top for separation */}
              <IconButton
                color="inherit"
                size="large"
                href="https://www.linkedin.com/company/vems-energymanagment"
                aria-label="LinkedIn"
                sx={{ 
                  '& .MuiSvgIcon-root': {
                    fontSize: '3rem', // Increase the font size for a bigger icon
                  },
                }}
              >
                <LinkedInIcon />
              </IconButton>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'right' }}>
            <Typography 
              variant="h6" // Change to h6 for a larger size
              sx={{ 
                fontWeight: 'bold',
                color: (theme) => theme.palette.text.primary,
                mb: 1
              }}
            >
              Contact
            </Typography>
            <Typography color="text.secondary" variant="body2">
              VEMS BV
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Zeeburgerdijk 490
            </Typography>
            <Typography color="text.secondary" variant="body2">
              1095 AL Amsterdam
            </Typography>
            <Typography color="text.secondary" variant="body2">
              Kvk: 93517513
            </Typography>
            <Typography color="text.secondary" variant="body2">
              VAT: NL866434422B01
            </Typography>
            <Typography color="text.secondary" variant="body2">
              service@v-ems.com
            </Typography>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}
