// DashboardInsights component, displays a grid of insights cards with sparkline charts

import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SparkLineChart } from '@mui/x-charts/SparkLineChart';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import EuroIcon from '@mui/icons-material/Euro';
import SavingsIcon from '@mui/icons-material/Savings';

const dashboardInsights = [
  {
    title: 'Total Yield',
    value: '835.3 kWh',
    change: '+15%',
    data: [
      650, 720, 630, 780, 670, 820, 690, 840, 
      710, 850, 730, 870, 750, 890, 780, 835
    ],
    interval: 'Monthly generation',
    icon: <ElectricMeterIcon />,
    color: '#2EA043'
  },
  {
    title: 'Expected Energy Bill',
    value: '€320',
    change: '-8%',
    data: [
      400, 380, 410, 360, 390, 350, 385, 340,
      375, 330, 365, 325, 355, 315, 345, 320
    ],
    interval: 'Monthly projection',
    icon: <EuroIcon />,
    color: '#F85149'
  },
  {
    title: 'PV Curtailment Savings',
    value: '€75',
    change: '+25%',
    data: [
      45, 65, 40, 70, 45, 72, 50, 68,
      55, 71, 58, 73, 62, 70, 67, 75
    ],
    interval: 'Monthly savings',
    icon: <SavingsIcon />,
    color: '#2EA043'
  }
];

const InsightCard = ({ title, value, interval, data, icon, change, color }) => {
  return (
    <Card 
      variant="outlined" 
      sx={{ 
        height: '100%', 
        flexGrow: 1,
        bgcolor: (theme) => theme.palette.mode === 'light' ? 'background.paper' : 'grey.900',
        borderColor: (theme) => theme.palette.mode === 'light' ? 'grey.200' : 'grey.800'
      }}
    >
      <CardContent>
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box sx={{ color: 'primary.main' }}>
                {icon}
              </Box>
              <Typography variant="body2">
                {title}
              </Typography>
            </Stack>
            
            <Typography variant="h5" component="p">
              {value}
            </Typography>
            
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {interval}
              </Typography>
              <Typography 
                variant="caption" 
                sx={{ 
                  color: color,
                  backgroundColor: `${color}1A`,  // 10% opacity
                  px: 1,
                  py: 0.5,
                  borderRadius: 1
                }}
              >
                {change}
              </Typography>
            </Stack>
          </Stack>

          <Box sx={{ width: 200, height: 60 }}>
            <SparkLineChart
              data={data}
              height={60}
              colors={[color]}
              showTooltip
              area
              curve="linear"
              sx={{
                '& .MuiAreaElement-root': {
                  fill: `${color}33`,
                }
              }}
            />
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};

InsightCard.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  interval: PropTypes.string.isRequired,
  change: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.number).isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
};

export default function DashboardInsights() {
  return (
    <Grid container spacing={2}>
      {dashboardInsights.map((item, index) => (
        <Grid item xs={12} md={4} key={index}>
          <InsightCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
} 