import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, 
  Typography, 
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Stack,
  Chip,
  IconButton,
  CircularProgress
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { alpha } from '@mui/material/styles';
import CreateAssetDialog from './CreateAssetDialog';
import ASSET_LIBRARY from '../data/AssetList';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

export default function AssetLibraryContent({ category = null }) {
  const [isCreateDialogOpen, setIsCreateDialogOpen] = React.useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = React.useState(false);
  const [editingAsset, setEditingAsset] = React.useState(null);
  const [assets, setAssets] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = React.useState(false);
  const [assetToDelete, setAssetToDelete] = React.useState(null);

  React.useEffect(() => {
    const loadAssets = async () => {
      if (!category) return;
      
      try {
        setLoading(true);
        const libraryAssets = await ASSET_LIBRARY.getAssets();
        setAssets(libraryAssets[category.id] || []);
      } catch (error) {
        console.error('Error loading assets:', error);
        // Handle error (show error message)
      } finally {
        setLoading(false);
      }
    };

    loadAssets();
  }, [category]);

  const handleCreateNewAsset = () => {
    setIsCreateDialogOpen(true);
  };

  const handleNewAssetCreated = (newAsset) => {
    if (!ASSET_LIBRARY[category.id]) {
      ASSET_LIBRARY[category.id] = [];
    }
    const updatedAssets = [...ASSET_LIBRARY[category.id], newAsset];
    ASSET_LIBRARY[category.id] = updatedAssets;
    setAssets(updatedAssets);
  };

  const handleEditAsset = (asset) => {
    setEditingAsset(asset);
    setIsEditDialogOpen(true);
  };

  const handleDeleteClick = (asset) => {
    setAssetToDelete(asset);
    setDeleteConfirmOpen(true);
  };

  const handleConfirmDelete = () => {
    if (assetToDelete) {
      const updatedAssets = assets.filter(asset => asset.id !== assetToDelete.id);
      setAssets(updatedAssets);
      ASSET_LIBRARY[category.id] = updatedAssets;
    }
    setDeleteConfirmOpen(false);
    setAssetToDelete(null);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setAssetToDelete(null);
  };

  const handleAssetUpdated = (updatedAsset) => {
    const updatedAssets = assets.map(asset => 
      asset.id === updatedAsset.id ? updatedAsset : asset
    );
    ASSET_LIBRARY[category.id] = updatedAssets;
    setAssets(updatedAssets);
  };

  if (!category) {
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%',
        p: 4
      }}>
        <Typography color="text.secondary">
          Select a category to view available assets
        </Typography>
      </Box>
    );
  }

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center',
        height: '100%' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h6" gutterBottom>
        {category.name}
      </Typography>
      <Typography color="text.secondary" paragraph>
        {category.description}
      </Typography>

      <Grid container spacing={2}>
        {assets.map((asset) => (
          <Grid item xs={12} sm={6} md={4} key={asset.id}>
            <Card variant="outlined">
              <CardContent>
                <Stack spacing={2}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    <Typography variant="h6" component="div">
                      {asset.name}
                    </Typography>
                    <Stack direction="row" spacing={1}>
                      <IconButton 
                        size="small" 
                        onClick={() => handleEditAsset(asset)}
                        sx={{ color: 'primary.main' }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton 
                        size="small" 
                        onClick={() => handleDeleteClick(asset)}
                        sx={{ color: 'error.main' }}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Stack>
                  </Box>

                  <Typography color="text.secondary" variant="body2">
                    {asset.manufacturer}
                  </Typography>
                  
                  {/* Specifications */}
                  <Stack spacing={1}>
                    {Object.entries(asset.specs).map(([key, value]) => (
                      <Box key={key} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant="body2" color="text.secondary">
                          {key}:
                        </Typography>
                        <Typography variant="body2">
                          {value}
                        </Typography>
                      </Box>
                    ))}
                  </Stack>

                  {/* Tags */}
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {asset.tags.map((tag) => (
                      <Chip 
                        key={tag} 
                        label={tag} 
                        size="small"
                        variant="outlined"
                      />
                    ))}
                  </Box>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12} sm={6} md={4}>
          <Card 
            variant="outlined"
            sx={(theme) => ({
              height: '100%',
              cursor: 'pointer',
              border: '2px dashed',
              borderColor: theme.palette.primary.main,
              bgcolor: 'transparent',
              '&:hover': {
                bgcolor: alpha(theme.palette.primary.main, 0.05),
                transform: 'translateY(-2px)',
              },
              transition: 'all 0.2s ease'
            })}
            onClick={handleCreateNewAsset}
          >
            <CardActionArea sx={{ height: '100%' }}>
              <CardContent>
                <Stack 
                  spacing={1} 
                  alignItems="center" 
                  justifyContent="center"
                  sx={{ py: 2 }}
                >
                  <AddIcon color="primary" sx={{ fontSize: 32 }} />
                  <Typography color="primary" variant="subtitle1">
                    Create New {category.name}
                  </Typography>
                  <Typography color="text.secondary" variant="body2" align="center">
                    Add a custom {category.name.toLowerCase()} to the library
                  </Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>

      <CreateAssetDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        category={category}
        onSubmit={handleNewAssetCreated}
      />

      <CreateAssetDialog
        open={isEditDialogOpen}
        onClose={() => {
          setIsEditDialogOpen(false);
          setEditingAsset(null);
        }}
        category={category}
        onSubmit={handleAssetUpdated}
        editingAsset={editingAsset}
      />

      <Dialog
        open={deleteConfirmOpen}
        onClose={handleCancelDelete}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete {assetToDelete?.name}?
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button 
            onClick={handleCancelDelete}
            variant="outlined"
            sx={{
              color: 'text.primary',
              borderColor: 'divider',
              '&:hover': {
                borderColor: 'primary.main',
                backgroundColor: 'action.hover'
              }
            }}
          >
            No
          </Button>
          <Button 
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

AssetLibraryContent.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.node,
    description: PropTypes.string
  })
};