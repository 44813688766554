// This widget shows the energy insights of the company. 
// It includes a card for each insight, with a title, value, trend, change, data, and interval.

import * as React from 'react';
import Grid from '@mui/material/Grid2';
import WidgetStatCard from './WidgetStatCard';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import Co2Icon from '@mui/icons-material/Co2';
import SavingsIcon from '@mui/icons-material/Savings';
import { orange } from '@mui/material/colors';

const energyData = [
  {
    title: 'Total Consumption',
    value: '450 kWh',
    trend: 'up',
    change: '+2.5% from last month',
    data: [
      380, 420, 390, 440, 400, 450, 410, 460, 420, 470,
      430, 480, 440, 490, 450, 500, 460, 510, 470, 520,
      480, 530, 490, 540, 500, 550, 510, 540, 500, 450
    ],
    interval: 'Compared to last month',
    icon: <ElectricMeterIcon />
  },
  {
    title: 'Solar Generation',
    value: '200 kWh',
    subtitle: '44% of total consumption',
    trend: 'neutral',
    data: [
      150, 190, 140, 200, 130, 210, 120, 220, 140, 230,
      160, 240, 150, 250, 140, 260, 130, 270, 120, 280,
      140, 290, 130, 300, 120, 310, 110, 300, 180, 200
    ],
    interval: 'Daily generation',
    icon: <SolarPowerIcon />,
    customColor: orange[500]
  },
  {
    title: 'Carbon Footprint',
    value: '0.8 tons',
    trend: 'down',
    change: '-5% from last 6 months',
    data: [
      1.0, 0.85, 0.95, 0.80, 0.90, 0.75, 0.85, 0.70, 0.80, 0.65,
      0.75, 0.60, 0.70, 0.55, 0.65, 0.50, 0.60, 0.45, 0.55, 0.40,
      0.50, 0.35, 0.45, 0.30, 0.40, 0.25, 0.35, 0.30, 0.75, 0.80
    ],
    interval: 'Last 6 months trend',
    icon: <Co2Icon />
  },
  {
    title: 'Energy Savings',
    value: '$120',
    trend: 'up',
    change: '+10% from last month',
    data: [
      85, 110, 80, 115, 75, 120, 70, 125, 65, 130,
      60, 135, 55, 140, 50, 145, 45, 150, 40, 155,
      35, 160, 30, 165, 25, 170, 20, 165, 110, 120
    ],
    interval: 'Monthly savings',
    icon: <SavingsIcon />
  }
];

export default function WidgetEnergyInsights() {
  return (
    <Grid 
      container 
      spacing={2} 
      sx={{ height: '100%' }}
    >
      {energyData.map((item, index) => (
        <Grid key={index} size={{ xs: 12, sm: 6 }}>
          <WidgetStatCard {...item} />
        </Grid>
      ))}
    </Grid>
  );
} 