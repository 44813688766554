import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  Grid,
  Typography,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AssetCategoryList from './components/AssetCategoryList';
import AssetLibraryContent from './components/AssetLibraryContent';

export default function AssetLibrary({ open, onClose }) {
  const [selectedCategory, setSelectedCategory] = React.useState(null);

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          height: '80vh',
          maxHeight: '900px'
        }
      }}
    >
      <DialogTitle sx={{ 
        m: 0, 
        p: 2, 
        display: 'flex', 
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider'
      }}>
        <Typography variant="h6" component="div">
          Asset Library
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        <Grid container sx={{ height: '100%' }}>
          {/* Left side - Categories */}
          <Grid item xs={3} sx={{ 
            borderRight: 1, 
            borderColor: 'divider',
            height: '100%',
            overflow: 'auto'
          }}>
            <AssetCategoryList 
              selectedCategory={selectedCategory}
              onCategorySelect={setSelectedCategory}
            />
          </Grid>
          
          {/* Right side - Asset Library Content */}
          <Grid item xs={9} sx={{ height: '100%', overflow: 'auto' }}>
            <AssetLibraryContent 
              category={selectedCategory}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

AssetLibrary.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};
