import * as React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import WidgetSimulationHeader from './WidgetSimulationHeader';
import WidgetSimulationControls from './WidgetSimulationControls';
import WidgetResultsView from './WidgetResultsView';

export default function ExistingSimulationGrid({ simulationData }) {
  return (
    <Grid container spacing={3} sx={{ width: '100%' }}>
      <Grid item xs={12}>
        <WidgetSimulationHeader 
          simulationName={simulationData.simulationName}
          status={simulationData.status}
          createdAt={simulationData.createdAt}
          startedAt={simulationData.startedAt}
          completedAt={simulationData.completedAt}
          errorMessage={simulationData.errorMessage}
        />
      </Grid>
      
      <Grid item xs={12}>
        <WidgetSimulationControls status={simulationData.status} />
      </Grid>

      <Grid item xs={12}>
        <WidgetResultsView 
          status={simulationData.status}
          startedAt={simulationData.startedAt}
          completedAt={simulationData.completedAt}
          errorMessage={simulationData.errorMessage}
        />
      </Grid>
    </Grid>
  );
}

ExistingSimulationGrid.propTypes = {
  simulationData: PropTypes.shape({
    simulationName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    startedAt: PropTypes.string,
    completedAt: PropTypes.string,
    errorMessage: PropTypes.string
  }).isRequired
}; 