import api from './api';

const simulationService = {
  // Get list of simulations with optional filters
  getSimulations: async (filters = {}) => {
    try {
      const queryParams = new URLSearchParams();
      if (filters.status) queryParams.append('status', filters.status);
      if (filters.page) queryParams.append('page', filters.page);
      if (filters.per_page) queryParams.append('per_page', filters.per_page);

      const response = await api.get(`/simulations?${queryParams}`);
      return {
        success: true,
        data: {
          simulations: response.data.simulations,
          currentPage: response.data.current_page,
          totalPages: response.data.pages,
          total: response.data.total
        }
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch simulations'
      };
    }
  },

  // Get a specific simulation by ID
  getSimulation: async (simulationId) => {
    try {
      const response = await api.get(`/simulations/${simulationId}`);
      return {
        success: true,
        data: response.data
      };
    } catch (error) {
      if (error.response?.status === 404) {
        return {
          success: false,
          error: 'Simulation not found'
        };
      }
      return {
        success: false,
        error: error.response?.data?.error || 'Failed to fetch simulation'
      };
    }
  }
};

export default simulationService; 