// Widget for the feedback form that opens in a dialog when clicking the feedback button in the side menu

import * as React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import { contactService } from '../../../services/api';

export default function WidgetFeedback({ open, onClose }) {
  const theme = useTheme();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [formData, setFormData] = React.useState({
    message: '',
  });
  const [formErrors, setFormErrors] = React.useState({});

  // User data that would normally come from a user context/auth system
  const userData = {
    name: 'Tess Green',
    email: 'tess@enterprise.com'
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    if (formErrors[name]) {
      setFormErrors((prev) => ({
        ...prev,
        [name]: '',
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.message.trim()) {
      errors.message = 'Message is required';
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setIsSubmitting(true);
    setStatus('Sending...');

    try {
      const response = await contactService.sendMessage({
        ...formData,
        ...userData,
        type: 'feedback'
      });
      
      if (response.success) {
        setStatus('Message sent successfully!');
        setFormData({ message: '' });
        setTimeout(() => {
          onClose();
          setStatus('');
        }, 1500);
      } else {
        throw new Error(response.data?.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setStatus('Failed to send message. Please try again later.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: theme.shadows[20],
        }
      }}
    >
      <DialogTitle sx={{ m: 0, p: 2, pb: 1 }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography variant="h6">Help us improve !</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{ color: 'text.secondary' }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>

      <form onSubmit={handleSubmit}>
        <DialogContent sx={{ p: 2 }}>
          <Stack spacing={2}>
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              multiline
              rows={8}
              error={Boolean(formErrors.message)}
              helperText={formErrors.message}
              sx={{
                '& .MuiInputBase-root': {
                  minHeight: '200px'
                }
              }}
            />
            {status && (
              <Typography 
                sx={{ 
                  color: status.includes('success') ? 'success.main' : 
                         status === 'Sending...' ? 'primary.main' :
                         'error.main' 
                }}
              >
                {status}
              </Typography>
            )}
          </Stack>
        </DialogContent>

        <DialogActions sx={{ p: 2, pt: 1 }}>
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            Send Feedback
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

WidgetFeedback.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};