// Vision component, serves as the vision section of the marketing page
// It includes a title, a description, and two images. 

import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';


export default function Vision() {
  return (
    <Box
      id="vision" // Ensure this id is set
      sx={(theme) => ({
        width: '100%',
        backgroundRepeat: 'no-repeat',
        ...theme.applyStyles('dark', {}),
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Typography
          variant="h1"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            fontSize: 'clamp(3rem, 10vw, 3.5rem)',
            textAlign: 'left',
            mb: 4,
          }}
        >
          VEMS
          <Box sx={{ display: 'flex', alignItems: 'center', ml: 2 }}>
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: 'inherit',
                color: 'primary.main',
                ...theme.applyStyles('dark', {
                  color: 'primary.light',
                }),
              })}
            >
              Vision
            </Typography>
          </Box>
        </Typography>

        <Typography
          sx={{
            textAlign: 'justify',
            color: 'text.secondary',
            width: { sm: '100%', md: '93%' },
            fontSize: '1.1rem',
            mb: 6,
          }}
        >
          VEMS vision is to lead the digital energy era by creating an interconnected integrated energy management ecosystem. 
          We envision all energy assets being digitized and interconnected, forming an intelligent energy network. This network can drastically reduce energy consumption, enhance energy accessibility, and improve market stability and reliability.
        </Typography>

        <Grid container spacing={4} alignItems="center">
          <Grid item xs={12} md={6}>
            <img 
              src="/images/MarketingPageImages/grid_congestion.svg" 
              alt="Grid Congestion" 
              style={{ width: '100%', height: 'auto', maxWidth: '500px' }} 
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <span>Tackling one of the biggest industrial problems:</span>
              <Box
                component="span"
                sx={(theme) => ({
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                Grid congestion
              </Box>
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '1.1rem',
                mb: 2,
                textAlign: 'justify',
              }}
            >
              Grid Congestion leads to energy supply inefficiencies and high price volatility, creating significant challenges for all types of enterprises.            
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '1.1rem',
                textAlign: 'justify',
              }}
            >
              Business owners often don&apos;t have the time to address this issue in a structured way. While they wish to embrace sustainable energy solutions, they feel lost in a complex and fragmented market, urgently needing quick solutions but struggling to navigate the overwhelming number of players.
            </Typography>
          </Grid>
        </Grid>
        
        <Grid container spacing={4} alignItems="center" sx={{ mt: 6 }}>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: '1.25rem',
                fontWeight: 'bold',
                mb: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <span>Our Solution:</span>
              <Box
                component="span"
                sx={(theme) => ({
                  color: 'primary.main',
                  ...theme.applyStyles('dark', {
                    color: 'primary.light',
                  }),
                })}
              >
                Smart Energy Management
              </Box>
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '1.1rem',
                mb: 2,
                textAlign: 'justify',
              }}
            >
              VEMS provides an integrated platform that helps businesses optimize their energy usage and navigate the complexities of the modern energy landscape.
            </Typography>
            <Typography
              sx={{
                color: 'text.secondary',
                fontSize: '1.1rem',
                textAlign: 'justify',
              }}
            >
              Through advanced analytics and automation, we enable enterprises to reduce their energy costs, improve sustainability, and contribute to grid stability while simplifying the entire energy management process.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
            <img 
              src="/images/MarketingPageImages/digital_energy_building.png" 
              alt="Energy Management" 
              style={{ width: '100%', height: 'auto', maxWidth: '500px' }} 
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
